import { Component } from '@angular/core';
import { ShowTotalesService } from 'app/services/global/ShowTotales.service'
import { FakeDbService } from 'app/fake-db/fake-db.service';

import { Apollo } from 'apollo-angular';
import { ChatFakeDb } from 'app/fake-db/chat';
import { CitasFakeDb } from 'app/fake-db/citas';
@Component({
    selector: 'footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
    //Puedo colocar un input aqui que me traiga la infomacion y paso con un input.
    Prefijo: string = 'Inicio.'
    /**
     * Constructor
     */
    // Debe ser DisableClient si el Total en cola es 0
    // y esta en modo DEvice
    public DisableClient: boolean = true;
    public TotalChatShow: Number = 0
    Token = localStorage.getItem("login");

    constructor(
        _ShowTotalesService: ShowTotalesService,
        private _FakeDbService: FakeDbService,
        private _ChatFDB: ChatFakeDb,
        private apollo: Apollo
    ) {
        this.DisableClient = true;
        _ShowTotalesService.showtotales.subscribe(Valor => {
            this.DisableClient = Valor
        })

        this.TotalEnCola()

        /* //Mantiene los totales de chat 
        this._FakeDbService.getTotalChat().then(TotalChat => {

            this.TotalChatShow = TotalChat

        }) */
    }

    TotalEnCola() {
        try {

            if (this.Token) {
                let token = this.Token.replace(/["']/g, "");
                this._ChatFDB.MSJInCola(this.apollo, token).then((Result: any) => {
                    if(Result != undefined && Result.length>0){
                        if(Result[0].hasOwnProperty("Total")){
                          this.TotalChatShow = Result[0].Total
                        }
                    }
                    
                })
            } else {
                this.TotalChatShow = 0
            }

        } catch (error) {
            console.error(error)
            this.TotalChatShow = 0
        }

    }

}
