import { Injectable, Output, EventEmitter } from '@angular/core';
import { Observable, Subscriber, from } from 'rxjs';
import { concatMap, delay, takeWhile } from 'rxjs/operators';
import { MutationService } from '../../fake-db/Mutation/mutation.service';
import { Apollo } from 'apollo-angular';
import { LoginSessionService } from '../../services/global/LoginSession.service';
import { EcommerceService } from '../../main/apps/e-commerce/service/e-commerce.service';
import { environment } from 'environments/environment.prod';
import { HttpClient } from '@angular/common/http';
import { SeguridadDatosService } from 'app/main/apps/dashboards/project/massive-message/seguridad-datos.service';
import { SweetAlertService } from 'app/services/global/sweet-alert.service';
import { SeguridadDatos } from 'app/services/global/bscript.service';

@Injectable({
  providedIn: 'root'
})
export class MassiveMessageService {

  @Output() countMessageSent: EventEmitter<number> = new EventEmitter<number>();
  @Output() countMessageBad: EventEmitter<number> = new EventEmitter<number>();
  @Output() numberOfMessageToSend: EventEmitter<number> = new EventEmitter<number>();
  @Output() moveIcon: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() showCounter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() showCounterInvoice: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() ShowCanceledInvoice: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() processSuccessful: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() processSuccessInvoice: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() processSuccessInvoiceCanceled: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() totalSent: EventEmitter<number> = new EventEmitter<number>();
  @Output() EndSendInvoice: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() InvoiceSucess: EventEmitter<any> = new EventEmitter<any>();
  public numberMessagesSent: number = 0;
  private idEmpresa: string = '';
  public stopObservable: boolean = true;
  public restOfNumbersAndMessages: any[] = [];
  public urlImageExist: boolean = false;
  public urlImageToSend: string = '';
  //private whatsapp: string = environment.ApiTLS;
  private factura: string =   environment.ApiFactura    /*'https://apitest2.thomas-talk.me/Envio' */ /* 'http://localhost:9007/Envio' */
  private akssl: string = environment.akssl;

  DataOrgInvoices: any = []
  RestOfInvoice: any = []
  countInvoiceSent: number = 0
  countInvoiceBad: number = 0
  MasiveInvoiceStop: boolean = false

  constructor(private mutationService: MutationService,
    private apollo: Apollo,
    private loginService: LoginSessionService,
    private eccomerceService: EcommerceService,
    private _httpClient: HttpClient,
    private security: SeguridadDatosService,
    private sweetAlertService: SweetAlertService,
    private _SeguridadDatos: SeguridadDatos

  ) {
    this.idEmpresa = this.loginService.GetDataUser().idEmpresa;
    this.showCounter.emit(false);
    this.showCounterInvoice.emit(false);
  }


  takeAllNumbers(allNumberAndMessage: any[], lic: string) {
    // Observable que toma todos los mensajes y emite de uno en uno
    // Y solo pasa al siguiente mensaje hasta que se envié el actual 
    this.restOfNumbersAndMessages = allNumberAndMessage;
    this.stopObservable = true;
    this.numberOfMessageToSend.emit(allNumberAndMessage.length);
    this.moveIcon.emit(true);
    this.processSuccessful.emit(false);
    return from(allNumberAndMessage).pipe(
      concatMap((contact) => this.sendOneMessage(contact, lic)),
      takeWhile(() => this.stopObservable)
    );
  }

  sendOneMessage(contact: any, lic: string): Observable<any> {
    // Procesa un único mensaje a la vez
    const { phone, message } = contact;
    return new Observable((subscriber: Subscriber<string>) => {
      let msgText = this.messageStructure(phone, message, lic);

      if (this.urlImageExist) {
        // Verifica si este mensaje tiene una imagen que debe ser enviada

        // Crea la estructura del mensaje que debe ser enviada
        let msgImage = this.messageStructure(phone, this.urlImageToSend, lic, '1');

        let sendToMsg = from([msgText, msgImage]);

        // Envia primero el texto de mensaje y luego la imagen  
        sendToMsg
          .subscribe((msg) => {
            this.mutationService.createChatCola(this.apollo, msg).then(res => {
              //res
            }).catch((err) => console.error(err))
          },
            (err) => console.error(err),
            () => {
              this.restOfNumbersAndMessages = this.restOfNumbersAndMessages.filter((contact_) => contact_ !== contact);
              //this.SendWaNotifv2(message, lic, phone, this.urlImageToSend);
              this.numberMessagesSent += 1;
              this.countMessageSent.emit(this.numberMessagesSent);
              subscriber.next('Mensaje enviado');
              subscriber.complete();
            });
        return;
      }

      let sendToMsg = from([msgText]);
      sendToMsg.subscribe(() => {
        this.mutationService.createChatCola(this.apollo, msgText).then(res => {
          //Estoy dentro de createChatCola
        })
          .catch((err) => console.error("Error con createChatCola", err));
      }, (err) => { console.error("Error", err) },
        () => {
          this.restOfNumbersAndMessages = this.restOfNumbersAndMessages.filter((contact_) => contact_ !== contact);
          //this.SendWaNotifv2(message, lic, phone);
          this.numberMessagesSent += 1;
          this.countMessageSent.emit(this.numberMessagesSent);
          subscriber.next('Mensaje enviado');
          subscriber.complete();

        });
    }).pipe(delay(1000));
  }

  messageStructure(phone: string, message: string, lic: string, contentType: string = '0') {
    const date = new Date();
    lic = lic.toString();
    lic = lic.includes('@c.us') ? lic : `${lic}@c.us`;
    phone = phone.toString();
    phone = phone.includes('@c.us') ? phone : `${phone}@c.us`;
    phone = phone.includes('58') ? phone : `58${phone}`;
    return {
      operador: "-1",   // Solo para pruebas
      idEmpresa: this.idEmpresa,
      phone: phone,
      idConversacion: `${lic.replace('@c.us', '')}_${phone.replace('@c.us', '')}_${date.getFullYear()}_${date.getMonth() + 1}-${date.getDate()}`,
      idmessage: `${phone.replace('@c.us', '')}_true_${this.eccomerceService.GenerateUniqueID()}`,
      me_to: "o",
      contentType: `${contentType}`,
      content: message,
      TimeRead: new Date().toISOString(),
      timestamp: Math.floor(date.getTime() / 1000.0),
      status: 0,
      statusc: 0,
      readmsg: false,
      lic: `${lic}`
    };
  }

  //Prueba de enviar mensajes masivos
  /*SendWaNotif(Content: string, lic: string, Number: string, Image?: string) {
    return new Promise(async (resolve: any, reject: any) => {
      try {
        let Phone = Number
        const DataWa = {
          "lic": lic,
          "Mensaje": Content,
          "Phone": Phone,
          "Archivos": [
            {
              "filename": "promo-image",
              "path": Image
            }
          ]
        }
        const headersData = {
          method: `SendWhats`,
          token: this.akssl,
          platform: "whatsapp",
        };
        this.MasterGETPOST(headersData, this.whatsapp, true, DataWa).then((data: any) => {
          //Esto lo agregue para probar
          resolve(data);
        }).catch((error: any) => {
          reject(error)
          console.error(error);
        })

        if (Phone) {
          resolve(true)
        }

      } catch (error) {
        reject(error)
      }
    })
  }

  SendWaNotifv2(Content: string, lic: string, Number: string, Image?: string) {
    return new Promise(async (resolve: any, reject: any) => {
      try {
        console.log("SendWaNotifv2");
        let Phone = Number
        const DataWa = {
          "lic":this._SeguridadDatos.encrypt(lic),
          "Mensaje": this._SeguridadDatos.encrypt(Content),
          "Phone": this._SeguridadDatos.encrypt(Phone),
          "Archivos":[
            {
              "filename": this._SeguridadDatos.encrypt("promo-image"),
              "path": this._SeguridadDatos.encrypt(Image)
            }
          ]
        }

        const headersData = {
          'method':'SendWhats',
          'directory': 'Envio',
          'token': environment.akssl,
          'platform': 'whatsapp'
      }

        this.MasterGETPOST(headersData, this.whatsapp, true, DataWa).then((data: any) => {
          //Esto lo agregue para probar
          if(data){
            resolve(this._SeguridadDatos.Descryp(data.message));
          }else{
            reject(false);
          }
        }).catch((error: any) => {
          reject(error)
          console.error(error);
        })

        if (Phone) {
          resolve(true)
        }

      } catch (error) {
        reject(error)
      }
    })
  }*/

  MasterGETPOST(headersData: any, url: string, post?: boolean, body?: any) {
    return new Promise(async (resolve: any, reject: any) => {
      if (post) {
        this.security.EncrypDataHash(headersData).then((headers: any) => {
          console.log(body);
          console.log(headers);
          this._httpClient.post(url, body, { headers }).subscribe((res: any) => {
            let jsonres;
            try {
              if (this.isJsonString(res)) {
                jsonres = JSON.parse(res)
              } else {
                jsonres = res
              }
              resolve(jsonres);
            } catch (error) {
              console.error(error)
            }
          })
        }).catch((error: any) => {
          reject(error)
        })
      }
      else {
        this.security.EncrypDataHash(headersData).then((headers: any) => {
          this._httpClient.get(url, { headers }).subscribe((res: any) => {
            let jsonres;
            try {
              if (this.isJsonString(res)) {
                jsonres = JSON.parse(res)
              } else {
                jsonres = res
              }
              resolve(jsonres);
            } catch (error) {
              console.error(error)
            }
          })
        }).catch((error: any) => {
          reject(error)
        })
      }

    })
  }

  isJsonString(jsonToParse: any) {
    try {
      JSON.parse(jsonToParse);
    } catch (e) {
      return false;
    }
    return true;
  }


  ReceiberFacturas(Data: any, TipoFactura: String) {
    try {
      //este metodo es el intermediario, ya que recibe toda la data y emiute los valores iniciales
      if (Data && Data.length > 0) {
        this.DataOrgInvoices = Data
        this.RestOfInvoice = Data
        this.stopObservable = true;
        this.numberOfMessageToSend.emit(Data.length);
        this.moveIcon.emit(true);
        this.processSuccessInvoice.emit(false);
        this.Proccess_Send_Invoice(Data, TipoFactura)
      }
    } catch (error) {
      console.error(error)
    }
  }

  Proccess_Send_Invoice(AllDataInvoice: any, TipoFactura: String) {
    try {
      //En este metodo se hace todo el proceso 
      let Pos_Invices: any
      let Pos_Bad_Invices: any
      //verifico que  tengo data 
      if (AllDataInvoice && AllDataInvoice.length > 0 && this.MasiveInvoiceStop === false) {
        if (TipoFactura === "Lote") {
          //Aqui es para facturar en lote
          const LimitInvoice = 10 //este limite se puede cambiar
          const DataInvoiceSend = AllDataInvoice.slice(0, LimitInvoice)//obtengo la data desde la posicion 0 hasta el limited de facturas definifo
          //voy hacer el envio de las facturas
          this.SendInvoice_Lote(DataInvoiceSend).then(async (Res: any) => {
            if (Res && Res.status === true && Res.Message) {
              //ocurrio un error y muestro el mensaje de error en una alerta
              this.sweetAlertService.ShowError(Res.Message)
            }

            //recibo la repuesta de que todo esta bien 
            this.countInvoiceSent += Res.Data.length //calculo el total
            this.countMessageSent.emit(this.countInvoiceSent);//emito el total de facturas generadas
            this.countInvoiceBad += Res.ErrorData.length//calculo el total
            this.countMessageBad.emit(this.countInvoiceBad)//emito el total de facturas no generas

            for (let index = 0; index < Res.Data.length; index++) {
              //este ciclo for es para poder colocar el status a las facturas generadas
              Pos_Invices = await this.DataOrgInvoices.findIndex((Position: any) => Position.ID_Json === Res.Data[index].ID_Json)//busco la posicion de la factura dentro del arreglo
              this.DataOrgInvoices[Pos_Invices].STATUS = "ENVIADO";//coloco el estatus
              this.DataOrgInvoices[Pos_Invices].FACTURA = Res.Data[0].FACTURA//coloco el numero de factura
              this.DataOrgInvoices[Pos_Invices].URLFACTURA = Res.Data[0].UrlFact//se coloca la url (actualmente con The Factory no se usa)
            }

            for (let IndexBad = 0; IndexBad < Res.ErrorData.length; IndexBad++) {
              //este ciclo for es para poder colocar el status de lasfacturas no generadas
              Pos_Bad_Invices = await this.DataOrgInvoices.findIndex((Position: any) => Position.ID_Json === Res.ErrorData[IndexBad].ID_Json)//busco la posicion de la factura dentro del arreglo
              this.DataOrgInvoices[Pos_Bad_Invices].STATUS = `FALLO ${Res.message ? "(" + Res.Mensaje + ")" : Res.ErrorData[IndexBad].Menssage ? "(" + Res.ErrorData[IndexBad].Menssage + ")" : ""}`;
            }

            AllDataInvoice = AllDataInvoice.slice(LimitInvoice, AllDataInvoice.length)//Borro del arreglo los datos que ya se generaron y se dejan lo que faltan por recorrer
            if (this.MasiveInvoiceStop === false) {
              this.Proccess_Send_Invoice(AllDataInvoice, TipoFactura)
            } else {
              //Envio detenido
              this.moveIcon.emit(false)
            }

          }).catch((err: any) => console.error(err))

        } else {
          //aqui se hace la facturacion normal 
          this.SendInvoice_Normal(AllDataInvoice[0]).then(async (Res: any) => {
            if (Res) {
              //Al recibir la data del api busco la posicion de la factura en el arreglo
              Pos_Invices = await this.DataOrgInvoices.findIndex((Position: any) => Position.ID_Json === AllDataInvoice[0].ID_Json)

              if (Res.status === true) {
                //si el status es true quiere decir que si se logro generar la factura
                this.countInvoiceSent += 1 //se le suma uno al total que se lleva de las facturas generadas
                this.countMessageSent.emit(this.countInvoiceSent);//se emite la data
                this.DataOrgInvoices[Pos_Invices].STATUS = "ENVIADO";//se le asigna el status de enviado
                this.DataOrgInvoices[Pos_Invices].FACTURA = Res.Data[0].FACTURA//se le asigna el numero de dicha factura
                this.DataOrgInvoices[Pos_Invices].URLFACTURA = Res.Data[0].UrlFact//se le coloca la url de la factura (Actualmente no se usa con The Factory)
              } else {
                //no se genero la factura
                this.countInvoiceBad += 1//se le suma 1 a las facturas no generadas
                this.countMessageBad.emit(this.countInvoiceBad)//se emite el valor
                this.DataOrgInvoices[Pos_Invices].STATUS = `FALLO (${Res.message})`;//se le asigna el total y el mensaj donde se puede ver el motivo del error
              }
            }
            AllDataInvoice.shift()//borro la primera posicion del arreglo
            if (this.MasiveInvoiceStop === false) {
              //verifico que no hayan detenido el envio de las facturas
              setTimeout(() => {
                //Despues de 1 segundo vuelvo a llamar el metodo 
                this.Proccess_Send_Invoice(AllDataInvoice, TipoFactura)
              }, 1000);
            } else {
              //Envio detenido
              this.moveIcon.emit(false)
            }
          }).catch((err: any) => console.error(err))
        }

      } else if (this.MasiveInvoiceStop === true) {
        //si tengo data pero MasiveInvoiceStop esta en true significa que detuvieron el envio
        //Envio detenido
        this.moveIcon.emit(false)
      } else {
        //si no tengo data detengo todo el proceso
        this.showCounterInvoice.emit(false);
        this.countMessageSent.emit(this.countInvoiceSent)
        this.countMessageBad.emit(this.countInvoiceBad)
        this.countInvoiceSent = 0
        this.countInvoiceBad = 0
        this.processSuccessInvoice.emit(true);
      }

    } catch (error) {
      console.error(error)
    }
  }

  SendInvoice_Normal(Datos: any) {
    return new Promise(async (resolve: any, reject: any) => {
      try {//metodo para enviar las facturas 1 a 1
        const headersData = {
          method: `GeneratedInvoice`,
          token: this.akssl,
          platform: "ThomasV11",
          facturacion: "Normal"
        }
        this.MasterGETPOST(headersData, this.factura, true, Datos).then((ResData: any) => {
          resolve(ResData)
        }).catch((err: any) => {
          console.error(err)
          reject(err)
        })

      } catch (error) {
        console.error(error)
        reject(error)
      }
    })
  }
  SendInvoice_Lote(Datos: any) {
    return new Promise(async (resolve: any, reject: any) => {
      try { // metodo para enviar las facturas en lote
        const headersData = {
          method: `GeneratedInvoice`,
          token: this.akssl,
          platform: "ThomasV11",
          facturacion: "Lote"
        }
        this.MasterGETPOST(headersData, this.factura, true, Datos).then((ResData: any) => {
          resolve(ResData)
        }).catch((err: any) => {
          console.error(err)
          reject(err)
        })

      } catch (error) {
        console.error(error)
        reject(error)
      }
    })
  }

  AnularInvoice(Datos: any) {
    return new Promise(async (resolve: any, reject: any) => {
      try {//en este metodo se envia la factura que se desea anular
        const headersData = {
          method: `AnularFactura`,
          token: this.akssl,
          platform: "ThomasV11",
        }
        this.MasterGETPOST(headersData, this.factura, true, Datos).then((ResData: any) => {
          resolve(ResData)
        }).catch((err: any) => {
          console.error(err)
          reject(err)
        })

      } catch (error) {
        console.error(error)
        reject(error)
      }
    })
  }

  GetInvoice(Datos: any) {
    return new Promise(async (resolve: any, reject: any) => {
      try {//aca se consulto la factura generada
        const headersData = {
          method: `ConsultarDocumento`,
          token: this.akssl,
          platform: "ThomasV11",
        }
        this.MasterGETPOST(headersData, this.factura, true, Datos).then((ResData: any) => {
          resolve(ResData)
        }).catch((err: any) => {
          console.error(err)
          reject(err)
        })

      } catch (error) {
        console.error(error)
        reject(error)
      }
    })
  }

}
