import { HttpClient } from "@angular/common/http";
import { EventEmitter } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Apollo } from "apollo-angular";
import gql from "graphql-tag";

export class ClientesFakeDb {
    static apollo: Apollo
    public static Counting: EventEmitter<Number> = new EventEmitter();
    public static EmiterCampos: EventEmitter<any> = new EventEmitter();
    public static CamposReady: boolean = false

    public static configShow = {
        title: 'Clientes',
        TitleColumShow: ['Avater', 'Nombre', 'Agentes', 'Cola', 'Estatus', 'Menú'],
        columns: ['logo', 'name', 'UserOnline', 'MsgCola', 'active', 'menu'],
        type: ['avatar', 'string', 'string', 'string', 'img', 'menu'],
        menu: ["Editar/edit", "Eliminar/delete"],
    }

    public static clientes = []  /*[
        {
            'id'              : '1',
            'name'            : 'Cable Hogar',
            'licencia'        : 345545,
            'correo'          : 'cablehogar@gmail.com',
            'Pais'            : 'El Paraiso',
            'rif'             : 'J-4516156-0',
            'telefono'        : '4165269874',
            'prefijo'         : '+58',
            'metodo_pago'     :  3,
            'UserOnline': '3/10',
            'MsgCola': 75,
            'handle'          : 'cable-hogar',
            'logo' : 'assets/images/ecommerce/CH101.jpeg',
            'active'          : 'check/green',
            'categories'      : [
                'Canvas Print',
                'Nature'
            ],
            'tags'            : [
                'Caracas',
                'nature'
            ],
            'featuredImageId' : 1,
            'images'          : [
                {
                    'id'  : 0,
                    'title':'img0',
                    'url' : 'assets/images/ecommerce/CH101.jpeg',
                    'type': 'image',
                    'message': 'Proident et sunt nostrud eiusmod ex elit tempor ipsum dolore amet laborum.',
                    'owner':   'Cable Hogar',
                    'size'     : '1.2 Mb',
                    'created'  : 'July 8, 2017',
                    'modified' : 'July 8, 2017',
                    'available'  : true,
                },
                {
                    'id'  : 1,
                    'url' : 'assets/images/ecommerce/CH102.jpeg',
                    'title':'img1',
                    'type': 'image',
                    'message': 'Anim quis adipisicing qui sint quis aute sint sit eu ullamco.',
                    'owner':   'Cable Hogar',
                    'size'     : '1.2 Mb',
                    'created'  : 'July 8, 2017',
                    'modified' : 'July 8, 2017',
                    'available'  : true,
                    
                },
                {
                    'id'  : 2,
                    'title':'img2',
                    'url' : 'assets/images/ecommerce/CH103.jpeg',
                    'type': 'image',
                    'message': 'Anim quis adipisicing qui sint quis aute sint sit eu ullamco.',
                    'owner':   'Cable Hogar',
                    'size'     : '1.2 Mb',
                    'created'  : 'July 8, 2017',
                    'modified' : 'July 8, 2017',
                    'available'  : true,
                }           
            ],
            
             
        }        
    ];  */

    public static orders = [
        {
            'id': 1,
            'reference': '70d4d7d0',
            'subtotal': '39.97',
            'tax': '77.44',
            'discount': '-10.17',
            'total': '73.31',
            'date': '2018/04/25 02:07:59',
            'customer': {
                'id': 1,
                'firstName': 'Dollie',
                'lastName': 'Bullock',
                'avatar': 'assets/images/avatars/Abbott.jpg',
                'company': 'Saois',
                'jobTitle': 'Digital Archivist',
                'email': 'abbott@withinpixels.com',
                'phone': '+1-202-555-0175',
                'invoiceAddress': {
                    'address': '704 6th Ave, New York, NY 10010, USA',
                    'lat': 40.7424739,
                    'lng': -73.99283919999999
                },
                'shippingAddress': {
                    'address': '377 E South Omaha Bridge Rd, Council Bluffs, IA 51501, USA',
                    'lat': 41.2183223,
                    'lng': -95.8420876
                }
            },
            'products': [
                {
                    'id': 1,
                    'name': 'A Walk Amongst Friends - Canvas Print',
                    'price': '10.24',
                    'quantity': 1,
                    'total': '10.24',
                    'image': 'assets/images/ecommerce/a-walk-amongst-friends.jpg'
                },
                {
                    'id': 2,
                    'name': 'Lago di Braies - Canvas Print',
                    'price': '24.62',
                    'quantity': 1,
                    'total': '24.62',
                    'image': 'assets/images/ecommerce/lago-di-braies.jpg'
                },
                {
                    'id': 3,
                    'name': 'Never Stop Changing - Canvas Print',
                    'price': '49.29',
                    'quantity': 1,
                    'total': '49.29',
                    'image': 'assets/images/ecommerce/never-stop-changing.jpg'
                }
            ],
            'status': [
                {
                    'id': 13,
                    'name': 'On pre-order (not paid)',
                    'color': 'purple-300',
                    'date': '2018/04/03 10:06:18'
                },
                {
                    'id': 1,
                    'name': 'Awaiting check payment',
                    'color': 'blue-500',
                    'date': '2018/03/17 18:28:37'
                }
            ],
            'payment': {
                'transactionId': '2a894b9e',
                'amount': '73.31',
                'method': 'Credit Card',
                'date': '2018/02/23 15:50:23'
            },
            'shippingDetails': [
                {
                    'tracking': '',
                    'carrier': 'TNT',
                    'weight': '10.44',
                    'fee': '7.00',
                    'date': '2018/04/10 07:03:52'
                }
            ]
        },
        {
            'id': 2,
            'reference': '2003479c',
            'subtotal': '98.68',
            'tax': '45.55',
            'discount': '-10.25',
            'total': '24.51',
            'date': '2018/11/07 15:47:31',
            'customer': {
                'id': 1,
                'firstName': 'Holmes',
                'lastName': 'Hines',
                'avatar': 'assets/images/avatars/Abbott.jpg',
                'company': 'Saois',
                'jobTitle': 'Digital Archivist',
                'email': 'abbott@withinpixels.com',
                'phone': '+1-202-555-0175',
                'invoiceAddress': {
                    'address': '704 6th Ave, New York, NY 10010, USA',
                    'lat': 40.7424739,
                    'lng': -73.99283919999999
                },
                'shippingAddress': {
                    'address': '377 E South Omaha Bridge Rd, Council Bluffs, IA 51501, USA',
                    'lat': 41.2183223,
                    'lng': -95.8420876
                }
            },
            'products': [
                {
                    'id': 1,
                    'name': 'A Walk Amongst Friends - Canvas Print',
                    'price': '10.24',
                    'quantity': 1,
                    'total': '10.24',
                    'image': 'assets/images/ecommerce/a-walk-amongst-friends.jpg'
                },
                {
                    'id': 2,
                    'name': 'Lago di Braies - Canvas Print',
                    'price': '24.62',
                    'quantity': 1,
                    'total': '24.62',
                    'image': 'assets/images/ecommerce/lago-di-braies.jpg'
                },
                {
                    'id': 3,
                    'name': 'Never Stop Changing - Canvas Print',
                    'price': '49.29',
                    'quantity': 1,
                    'total': '49.29',
                    'image': 'assets/images/ecommerce/never-stop-changing.jpg'
                }
            ],
            'status': [
                {
                    'id': 2,
                    'name': 'Payment accepted',
                    'color': 'green-500',
                    'date': '2018/10/04 08:54:33'
                },
                {
                    'id': 1,
                    'name': 'Awaiting check payment',
                    'color': 'blue-500',
                    'date': '2018/05/03 03:43:04'
                }
            ],
            'payment': {
                'transactionId': '79c640c8',
                'amount': '24.51',
                'method': 'Check',
                'date': '2018/04/22 04:49:49'
            },
            'shippingDetails': [
                {
                    'tracking': '',
                    'carrier': 'USPS',
                    'weight': '2.92',
                    'fee': '4.00',
                    'date': '2018/07/11 14:57:12'
                }
            ]
        }];

    public static productsorg = [
        {
            'id': '1',
            'name': 'A Walk Amongst Friends - Canvas Print',
            'handle': 'a-walk-amongst-friends-canvas-print',
            'description': 'Officia amet eiusmod eu sunt tempor voluptate laboris velit nisi amet enim proident et. Consequat laborum non eiusmod cillum eu exercitation. Qui adipisicing est fugiat eiusmod esse. Sint aliqua cupidatat pariatur mollit ad est proident reprehenderit. Eiusmod adipisicing laborum incididunt sit aliqua ullamco.',
            'categories': [
                'Canvas Print',
                'Nature'
            ],
            'tags': [
                'canvas-print',
                'nature'
            ],
            'featuredImageId': 1,
            'images': [
                {
                    'id': 0,
                    'url': 'assets/images/ecommerce/a-walk-amongst-friends.jpg',
                    'type': 'image'
                },
                {
                    'id': 1,
                    'url': 'assets/images/ecommerce/braies-lake.jpg',
                    'type': 'image'
                },
                {
                    'id': 2,
                    'url': 'assets/images/ecommerce/fall-glow.jpg',
                    'type': 'image'
                },
                {
                    'id': 3,
                    'url': 'assets/images/ecommerce/first-snow.jpg',
                    'type': 'image'
                },
                {
                    'id': 4,
                    'url': 'assets/images/ecommerce/lago-di-braies.jpg',
                    'type': 'image'
                },
                {
                    'id': 5,
                    'url': 'assets/images/ecommerce/lago-di-sorapis.jpg',
                    'type': 'image'
                },
                {
                    'id': 6,
                    'url': 'assets/images/ecommerce/never-stop-changing.jpg',
                    'type': 'image'
                },
                {
                    'id': 7,
                    'url': 'assets/images/ecommerce/reaching.jpg',
                    'type': 'image'
                },
                {
                    'id': 8,
                    'url': 'assets/images/ecommerce/morain-lake.jpg',
                    'type': 'image'
                },
                {
                    'id': 9,
                    'url': 'assets/images/ecommerce/yosemite.jpg',
                    'type': 'image'
                }
            ],
            'priceTaxExcl': 9.309,
            'priceTaxIncl': 10.24,
            'taxRate': 10,
            'comparedPrice': 19.90,
            'quantity': 3,
            'sku': 'A445BV',
            'width': '22',
            'height': '24',
            'depth': '15',
            'weight': '3',
            'extraShippingFee': 3.00,
            'active': true
        },
        {
            'id': '2',
            'name': 'Braies Lake - Canvas Print',
            'handle': 'braies-lake-canvas-print',
            'description': 'Duis anim est non exercitation consequat. Ullamco ut ipsum dolore est elit est ea elit ad fugiat exercitation. Adipisicing eu ad sit culpa sint. Minim irure Lorem eiusmod minim nisi sit est consectetur.',
            'categories': [
                'Canvas Print',
                'Nature'
            ],
            'tags': [
                'canvas-print',
                'nature'
            ],
            'featuredImageId': 2,
            'images': [
                {
                    'id': 0,
                    'url': 'assets/images/ecommerce/a-walk-amongst-friends.jpg',
                    'type': 'image'
                },
                {
                    'id': 1,
                    'url': 'assets/images/ecommerce/braies-lake.jpg',
                    'type': 'image'
                },
                {
                    'id': 2,
                    'url': 'assets/images/ecommerce/fall-glow.jpg',
                    'type': 'image'
                },
                {
                    'id': 3,
                    'url': 'assets/images/ecommerce/first-snow.jpg',
                    'type': 'image'
                },
                {
                    'id': 4,
                    'url': 'assets/images/ecommerce/lago-di-braies.jpg',
                    'type': 'image'
                },
                {
                    'id': 5,
                    'url': 'assets/images/ecommerce/lago-di-sorapis.jpg',
                    'type': 'image'
                },
                {
                    'id': 6,
                    'url': 'assets/images/ecommerce/never-stop-changing.jpg',
                    'type': 'image'
                },
                {
                    'id': 7,
                    'url': 'assets/images/ecommerce/reaching.jpg',
                    'type': 'image'
                },
                {
                    'id': 8,
                    'url': 'assets/images/ecommerce/morain-lake.jpg',
                    'type': 'image'
                },
                {
                    'id': 9,
                    'url': 'assets/images/ecommerce/yosemite.jpg',
                    'type': 'image'
                }
            ],
            'priceTaxExcl': 22.381,
            'priceTaxIncl': 24.62,
            'taxRate': 10,
            'comparedPrice': 29.90,
            'quantity': 92,
            'sku': 'A445BV',
            'width': '22',
            'height': '24',
            'depth': '15',
            'weight': '3',
            'extraShippingFee': 3.00,
            'active': true
        }];




    public static async CargasClientes(Token: string) {
        return this.clientes //ClientesFakeDb.QueryEmpresa(Token)
    }

    /* static async ReloadClientes(apollo: Apollo, Token: string, _httpClient: HttpClient) {
        let users = apollo.watchQuery<any>({
            query: clientesByUserALL,
            variables: {
                input: Token
            }
        })
            .valueChanges
            .subscribe(({ data, loading }) => {

                //HACE UN RESET DE BD http.post('commands/resetdb', undefined);
                if (data.clientesByUser != null) {
                    const Token = sessionStorage.getItem('DataLogin')
                    const helper = new JwtHelperService();
                    const decodedToken = helper.decodeToken(Token);
                    let Operador = {
                        id: decodedToken.Data.idUser,
                        name: decodedToken.Data.Nombre,
                        idCliente: decodedToken.Data.idCliente,
                    }
                    //*filtramos los contactos por el idCliente
                    //let contactosFiltrados=  data.clientesByUser.filter((cliente:any)=>Operador.idCliente.includes(cliente.idCliente)  ) 
                    data.clientesByUser = data.clientesByUser.filter((cliente: any) => Operador.idCliente.includes(cliente.id))


                    const _httpClientCampos: HttpClient = _httpClient
                    // Campos temporales por falta de esos datos...
                    let ClientesCampos = []
                    let ClientesEtiquetas = []
                    let ClientesAtajos = []
                    let ClientesHorarios = []
                    let ClientesBot = []
                    this.Counting.emit(data.clientesByUser.length);

                    for (let index = 0; index < data.clientesByUser.length; index++) {
                        //const element = data.clientesByUser[index];
                        //Estos campos ya fueron agregados OJO
                        data.clientesByUser[index].UserOnline = '4/10'

                        // data.clientesByUser[index].MsgCola = 76
                        data.clientesByUser[index].active = data.clientesByUser[index].active ? 'check/green' : 'check/red'

                        try {
                            // Agrega los clientes del usuario que hace login.
                            _httpClient.post('api/clientes/', data.clientesByUser[index])
                                .subscribe(() => {
                                    // Agregar campos

                                    if (data.clientesByUser[index].campos)  // Toca validar que pasa cuando no tiene nada de ajalos el cliente
                                    {
                                        const DataAdd = {
                                            id: data.clientesByUser[index].id,
                                            campos: data.clientesByUser[index].campos
                                        }
                                        ClientesCampos.push(DataAdd)
                                    }

                                    // Agregar Canales
                                    // Agregar Etiquedas

                                    if (data.clientesByUser[index].etiquetas) {
                                        ClientesEtiquetas.push({
                                            id: data.clientesByUser[index].id,
                                            etiquetas: data.clientesByUser[index].etiquetas
                                        })
                                    }

                                    // Agregar Atajos
                                    if (data.clientesByUser[index].atajos) {
                                        ClientesAtajos.push({
                                            id: data.clientesByUser[index].id,
                                            atajos: data.clientesByUser[index].atajos
                                        })
                                    }

                                    // Agregar Horarios
                                    if (data.clientesByUser[index].Horarios) {
                                        ClientesHorarios.push(data.clientesByUser[index].Horarios)
                                    }

                                    if (data.clientesByUser[index].bot) {
                                        ClientesBot.push({
                                            id: data.clientesByUser[index].id,
                                            idCliente: data.clientesByUser[index].id,
                                            Lic: data.clientesByUser[index].telefono,
                                            table: {
                                                bot: data.clientesByUser[index].bot
                                            }
                                        })
                                    }

                                });
                        } catch (error) {

                        }
                    }

                    const DataAdd = {
                        item: { id: 1 },
                        columOLD: ['title', 'type', 'Lic', 'requerido', 'menú'],
                        colum: [
                            { colum: 'title', type: 'string', head: 'Campo', visible: true, isModelProperty: true },
                            { colum: 'type', type: 'string', head: 'Tipo', visible: true, isModelProperty: true },
                            { colum: 'Lic', type: 'string', head: 'Canal', visible: true, isModelProperty: true },
                            { colum: 'requerido', type: 'boolean', head: 'Requerido', visible: true, isModelProperty: true },
                            { colum: 'ubicacion', type: 'string', head: 'Ubicación', visible: true, isModelProperty: true },
                            { colum: 'Menu', type: 'menu', head: 'Menú', visible: true }

                        ],
                        type: ['string', 'string', 'string', 'boolean', 'menu'],
                        TitleColumShow: ['Campo', 'Canal', 'Menú'],
                        menu: ["Editar/edit", "Eliminar/delete"],
                        clientes: ClientesCampos
                    }

                    _httpClientCampos.post('api/campos/1', DataAdd)
                        .subscribe((response: any) => { }, (Error) => console.error('CAMPOS' + Error));

                    const DateEtiquetas = {
                        item: { id: 1 },
                        colum: [
                            { colum: 'etiquetas', type: 'string', head: 'Etiquetas', visible: true, isModelProperty: true },
                            { colum: 'Canal', type: 'string', head: 'Canal', visible: true, isModelProperty: true },
                            { colum: 'Menu', type: 'menu', head: 'Menú', visible: true }
                        ],
                        type: ['string', 'string', 'menu'],
                        TitleColumShow: ['Etiquetas', 'Canal', 'Menú'],
                        menu: ["Editar/edit", "Eliminar/delete"],
                        clientes: ClientesEtiquetas
                    }
                    _httpClientCampos.post('api/etiquetas/1', DateEtiquetas)
                        .subscribe((response: any) => { }, (Error) => console.error('ETIQUETAS' + Error));


                    const DateAtajos = {
                        colum: [
                            { colum: 'abreviado', type: 'string', head: 'Abreviado', visible: true, isModelProperty: true },
                            { colum: 'texto', type: 'string', head: 'Texto', visible: true, isModelProperty: true },
                            { colum: 'Lic', type: 'string', head: 'Canal', visible: true, isModelProperty: true },
                            { colum: 'Menu', type: 'menu', head: 'Menú', visible: true }
                        ],
                        columns: ['abreviado', 'texto', 'Lic', 'menú'],
                        type: ['string', 'string', 'string', 'menu'],
                        TitleColumShow: ['Abreviado', 'Texto', 'Canal', 'Menú'],
                        Variables: ['@name', '@phone', '@saldo', '@contrato', '@ultimopago'],
                        menu: ["Editar/edit", "Eliminar/delete"],
                        clientes: ClientesAtajos
                    }
                    _httpClientCampos.post('api/atajos/1', DateAtajos)
                        .subscribe((response: any) => { }, (Error) => console.error('ATAJOS' + Error));

                    const DataHorarios = {
                        colum: [
                            { colum: 'descripcion', type: 'string', head: 'Descripción', visible: true, isModelProperty: false },
                            { colum: 'jornadas', type: 'string', head: 'Jornadas', visible: true, isModelProperty: true },
                            { colum: 'diainicio', type: 'string', head: 'DiaInicio', visible: true, isModelProperty: true },
                            { colum: 'diafin', type: 'string', head: 'DiaFin', visible: true, isModelProperty: true },
                            { colum: 'excepciones', type: 'string', head: 'Excepciones', visible: true, isModelProperty: true },
                            { colum: 'Menu', type: 'menu', head: 'Menú', visible: true }
                        ],
                        type: ['string', 'string', 'string', 'string', 'string', 'menu'],
                        TitleColumShow: ['Campo', 'Canal', 'Menú'],
                        menu: ["Editar/edit", "Eliminar/delete", "Asignar/how_to_reg"],
                        clientes: ClientesHorarios
                    }
                    _httpClientCampos.post('api/horarios/1', DataHorarios)
                        .subscribe((response: any) => { }, (Error) => console.error('Horarios' + Error));

                    const DataBot = {
                        title: 'Bot',
                        colum: [
                            { colum: 'contains', type: 'string', head: 'Contenido', visible: true, isModelProperty: false },
                            { colum: 'exact', type: 'string', head: 'Exacto', visible: true, isModelProperty: true },
                            { colum: 'Type', type: 'boolean', head: 'Canal', visible: true, isModelProperty: true },
                            { colum: 'response', type: 'string', head: 'Respuesta', visible: true, isModelProperty: true },
                            { colum: 'Menu', type: 'menu', head: 'Menú', visible: true }
                        ],
                        TitleColumShow: ['Contiene', 'Exacto', 'Respuesta', 'Tipo', 'Menú'],
                        menu: ["Editar/edit", "Eliminar/delete", "Agregar Lista Negra/accessible"],
                        Lics: ClientesBot
                    }

                    _httpClientCampos.post('api/bot/1', DataBot)
                        .subscribe((response: any) => { }, (Error) => console.error('Bots' + Error));

                }


            }, (Error) => { console.error(Error) });


        return users
    } */

    public static GetClientes(apollo: Apollo, token: any, _httpClient: HttpClient) {
        let Datos = apollo.watchQuery<any>({
            query: GetClientesByUser,
            variables: {
                token: token
            }
        })
            .valueChanges
            .subscribe((result) => {
                let data: any = result.data

                if (data != null) {
                    const Token = sessionStorage.getItem('DataLogin')
                    const helper = new JwtHelperService();
                    const decodedToken = helper.decodeToken(Token);
                    let Operador = {
                        id: decodedToken.Data.idUser,
                        name: decodedToken.Data.Nombre,
                        idCliente: decodedToken.Data.idCliente,
                    }
                    //* filtramos los contactos por el idCliente
                    //* let contactosFiltrados = data.clientesByUser.filter((cliente: any) => Operador.idCliente.includes(cliente.idCliente)) *
                    //data.ClienteXUser = data.ClienteXUser.filter((cliente: any) => Operador.idCliente.includes(cliente.id))

                    const _httpClientCampos: HttpClient = _httpClient
                    // Campos temporales por falta de esos datos...
                    let ClientesCampos = []
                    let ClientesEtiquetas = []
                    let ClientesAtajos = []
                    let ClientesHorarios = []
                    let ClientesCanales = []
                    let ClientesBot = []
                    if ('ClienteXUser' in data) { // Valido que exista data.ClienteXUser Jhonattan
                        if (Array.isArray(data.ClienteXUser)) {

                            this.Counting.emit(data.ClienteXUser.length);

                            for (let index = 0; index < data.ClienteXUser.length; index++) {
                                //const element = data.ClienteXUser[index];
                                //Estos campos ya fueron agregados OJO
                                data.ClienteXUser[index].UserOnline = '4/10'

                                // data.ClienteXUser[index].MsgCola = 76
                                data.ClienteXUser[index].active = data.ClienteXUser[index].active ? 'check/green' : 'check/red'

                                try {
                                    // Agrega los clientes del usuario que hace login.
                                    _httpClient.post('api/clientes/', data.ClienteXUser[index])
                                        .subscribe(() => {
                                            // Agregar campos

                                            if (data.ClienteXUser[index].campos)  // Toca validar que pasa cuando no tiene nada de ajalos el cliente
                                            {
                                                const DataAdd = {
                                                    id: data.ClienteXUser[index].id,
                                                    campos: data.ClienteXUser[index].campos
                                                }
                                                ClientesCampos.push(DataAdd)
                                            }

                                            // Agregar Canales
                                            // Agregar Etiquedas

                                            if (data.ClienteXUser[index].etiquetas) {
                                                ClientesEtiquetas.push({
                                                    id: data.ClienteXUser[index].id,
                                                    etiquetas: data.ClienteXUser[index].etiquetas
                                                })
                                            }

                                            // Agregar Atajos
                                            if (data.ClienteXUser[index].atajos) {
                                                ClientesAtajos.push({
                                                    id: data.ClienteXUser[index].id,
                                                    atajos: data.ClienteXUser[index].atajos
                                                })
                                            }

                                            // Agregar Horarios
                                            if (data.ClienteXUser[index].Horarios) {
                                                ClientesHorarios.push(data.ClienteXUser[index].Horarios);
                                            }

                                            if (data.ClienteXUser[index].bot) {
                                                ClientesBot.push({
                                                    id: data.ClienteXUser[index].id,
                                                    idCliente: data.ClienteXUser[index].id,
                                                    Lic: data.ClienteXUser[index].telefono,
                                                    table: {
                                                        bot: data.ClienteXUser[index].bot
                                                    }
                                                })
                                            }

                                            //Agregar Canales
                                            if (data.ClienteXUser[index].canales) {
                                                ClientesCanales.push({idCliente:data.ClienteXUser[index].id,canales:data.ClienteXUser[index].canales})
                                            }

                                        });
                                } catch (error) {

                                }
                            }
                        }
                    }


                    const DataAdd = {
                        item: { id: 1 },
                        columOLD: ['title', 'type', 'Lic', 'requerido', 'menú'],
                        colum: [
                            { colum: 'title', type: 'string', head: 'Campo', visible: true, isModelProperty: true },
                            { colum: 'type', type: 'string', head: 'Tipo', visible: true, isModelProperty: true },
                            { colum: 'Lic', type: 'string', head: 'Canal', visible: true, isModelProperty: true },
                            { colum: 'requerido', type: 'boolean', head: 'Requerido', visible: true, isModelProperty: true },
                            { colum: 'ubicacion', type: 'string', head: 'Ubicación', visible: true, isModelProperty: true },
                            { colum: 'Menu', type: 'menu', head: 'Menú', visible: true }

                        ],
                        type: ['string', 'string', 'string', 'boolean', 'menu'],
                        TitleColumShow: ['Campo', 'Canal', 'Menú'],
                        menu: ["Editar/edit", "Eliminar/delete"],
                        clientes: ClientesCampos
                    }

                    _httpClientCampos.post('api/campos/1', DataAdd)
                        .subscribe((response: any) => {
                            ClientesFakeDb.EmiterCampos.emit(true)
                            ClientesFakeDb.CamposReady = true

                        }, (Error) => console.error('CAMPOS' + Error));

                    const DateEtiquetas = {
                        item: { id: 1 },
                        colum: [
                            { colum: 'etiquetas', type: 'string', head: 'Etiquetas', visible: true, isModelProperty: true },
                            { colum: 'Canal', type: 'string', head: 'Canal', visible: true, isModelProperty: true },
                            { colum: 'Menu', type: 'menu', head: 'Menú', visible: true }
                        ],
                        type: ['string', 'string', 'menu'],
                        TitleColumShow: ['Etiquetas', 'Canal', 'Menú'],
                        menu: ["Editar/edit", "Eliminar/delete"],
                        clientes: ClientesEtiquetas
                    }
                    _httpClientCampos.post('api/etiquetas/1', DateEtiquetas)
                        .subscribe((response: any) => { }, (Error) => console.error('ETIQUETAS' + Error));

                    const DateAtajos = {
                        colum: [
                            { colum: 'abreviado', type: 'string', head: 'Abreviado', visible: true, isModelProperty: true },
                            { colum: 'texto', type: 'string', head: 'Texto', visible: true, isModelProperty: true },
                            { colum: 'Lic', type: 'string', head: 'Canal', visible: true, isModelProperty: true },
                            { colum: 'Menu', type: 'menu', head: 'Menú', visible: true }
                        ],
                        columns: ['abreviado', 'texto', 'Lic', 'menú'],
                        type: ['string', 'string', 'string', 'menu'],
                        TitleColumShow: ['Abreviado', 'Texto', 'Canal', 'Menú'],
                        Variables: ['@name', '@phone', '@saldo', '@contrato', '@ultimopago'],
                        menu: ["Editar/edit", "Eliminar/delete"],
                        clientes: ClientesAtajos
                    }
                    _httpClientCampos.post('api/atajos/1', DateAtajos)
                        .subscribe((response: any) => { }, (Error) => console.error('ATAJOS' + Error));

                    const DataHorarios = {
                        colum: [
                            { colum: 'descripcion', type: 'string', head: 'Descripción', visible: true, isModelProperty: false },
                            { colum: 'jornadas', type: 'string', head: 'Jornadas', visible: true, isModelProperty: true },
                            //{ colum: 'diainicio', type: 'string', head: 'DiaInicio', visible: true, isModelProperty: true },
                            //{ colum: 'diafin', type: 'string', head: 'DiaFin', visible: true, isModelProperty: true },
                            { colum: 'excepciones', type: 'string', head: 'Excepciones', visible: true, isModelProperty: true },
                            { colum: 'Menu', type: 'menu', head: 'Menú', visible: true }
                        ],
                        type: ['string', 'string', 'string', 'string', 'string', 'menu'],
                        TitleColumShow: ['Campo', 'Canal', 'Menú'],
                        menu: ["Editar/edit", "Eliminar/delete", "Asignar/how_to_reg"],
                        clientes: ClientesHorarios
                    }
                    _httpClientCampos.post('api/horarios/1', DataHorarios)
                        .subscribe((response: any) => { }, (Error) => console.error('Horarios' + Error));

                    const DataBot = {
                        title: 'Bot',
                        colum: [
                            { colum: 'contains', type: 'string', head: 'Contenido', visible: true, isModelProperty: false },
                            { colum: 'exact', type: 'string', head: 'Exacto', visible: true, isModelProperty: true },
                            { colum: 'Type', type: 'boolean', head: 'Canal', visible: true, isModelProperty: true },
                            { colum: 'response', type: 'string', head: 'Respuesta', visible: true, isModelProperty: true },
                            { colum: 'Menu', type: 'menu', head: 'Menú', visible: true }
                        ],
                        TitleColumShow: ['Contiene', 'Exacto', 'Respuesta', 'Tipo', 'Menú'],
                        menu: ["Editar/edit", "Eliminar/delete", "Agregar Lista Negra/accessible"],
                        Lics: ClientesBot
                    }

                    _httpClientCampos.post('api/bot/1', DataBot)
                        .subscribe((response: any) => { }, (Error) => console.error('Bots' + Error));

                    const DataCanales = {
                        Canales: ClientesCanales
                    }
                    _httpClientCampos.post('api/canales/1', DataCanales)
                        .subscribe((response: any) => { }, (Error) => console.error('canales' + Error));

                }

            });

    }


}

const ClienteXUser = gql`
  query clientesByUser($input:String){
    clientesByUser(input:$input){
                id: idCliente
                name: NombreCliente
                licencia
                correo
                Pais: Direccion                                
                rif
                telefono
                prefijo
                metodo_pago
                handle: NombreCliente
                logo
                active
            }
  }
`

const clientesByUserALL = gql`
  query clientesByUser($input:String){
    clientesByUser(input:$input){
                id:idCliente
                name:NombreCliente
                handle:NombreCliente
                correo
                licencia
                Pais: Direccion
                logo
                rif
                telefono
                prefijo
                metodo_pago
                logo
                active
                metodo_pago
                
                canales: Canales{
                    lic
                    type
                    version
                    totalmsg
                    online
                    status
                    bateria
                    ultconex
                }

                images: Galeria{
                    id
                    title
                    url
                    message
                    size
                    created
                    modified
                    available
                }

                campos:Campos{
                    id
                    title
                    type:tipo
                    requerido
                    ubicacion
                    Lic:Canales
                }

                atajos:Atajos{
                    id
                    abreviado
                    texto
                    Lic:Canales
                    Ubicacion
                }

                etiquetas:Etiquetas{
                    id
                    word
                    Lic:idCanal
                }

                Horarios{
                    idCliente
                    idHorario
                    Descripcion
                    Jornadas{
                        DiaInicio
                        DiaFin
                        HoraInicio
                        HoraFin
                    }
                    Excepciones{
                        Descripcion
                        Fecha
                    }
                }

                bot:Bots{
                    idBot
                    idCanal
                    bot{
                        contains
                        exact
                        tipo
                        response
                        apiURL
                        bData
                    }
                    noMatch
                }

                MsgCola:Msg_Cola
                UserOnline:Users_Online
            }
  }`


const GetClientesByUser = gql`
  query ClienteXUser($token:String){
    ClienteXUser(token:$token){
    id:idCliente
    name:NombreCliente
    handle:NombreCliente
    Pais:Direccion
    licencia
    correo
    rif
    telefono
    prefijo
    metodo_pago
    logo
    active
    canales:Canales{
      lic
      type
      version
      totalmsg
      online
      status
      bateria
      ultconex
    }
    images:Galeria{
      id
      owner
      title
      url
      message
      size
      created
      modified
      available
    }
    Horarios{
      idCliente
      idHorario
      Descripcion
      Jornadas{
        DiaInicio
        DiaFin
        HoraInicio
        HoraFin
      }
      Excepciones{
        Descripcion
        Fecha
      }
    }
    campos:Campos{
      id
      title
      type:tipo
      requerido
      ubicacion
      Lic:Canales
    }
    atajos:Atajos{
      id
      abreviado
      texto
      Canales
      Ubicacion
    }
    etiquetas:Etiquetas{
      id
      word
      Lic:idCanal
    }
    bot:Bots{
      idBot
      idCanal
      bot{
        contains
        exact
        tipo
        response
        apiURL
        bData
      }
      noMatch
    }
    MsgCola:Msg_Cola
    UserOnline:Users_Online
  }
  }`

//   bot:Bots{
//     idBot
//     idCanal
//     bot:Bot{
//         contains
//         exact
//         tipo
//         response
//         apiURL
//         bData
//     }

//     noMatch
// }

/*
const MutationByUser =gql`
  mutation UpdateClientes($IdCliente:String,$NombreCliente:String,$Direccion:String,$Pais:String,$correo:String,$rif:String,$telefono:String,$prefijo:String,$metodo_pago:Number,$logo:String,$active:Boolean){

        UpdateClientes($IdCliente,$NombreCliente,$Direccion,$correo,$rif,$telefono,$prefijo,$metodo_pago,$logo){
                id: idCliente: $IdCliente
                name: NombreCliente :$NombreCliente
                Pais: $Pais
                Direccion: $Direccion
                logo: $logo
                licencia: $licencia
                rif: $rif
                telefono: $telefono
                prefijo: $prefijo
                metodo_pago: $metodo_pago
                active: $active
                correo: $correo
            }
  }




images: Galeria{
                    id
                    title
                    url
                    message
                    size
                    created
                    modified
                    available
                }

  Horarios{
                        idCliente
                        idHorario
                        Descripcion
                    Jornadas{
                        DiaInicio
                        DiaFin
                        HoraInicio
                        HoraFin
                    }
                    Excepciones{
                        Descripcion
                        Fecha
                    }
                }



                bot:Bots{
                    idBot
                    idCanal
                    bot:Bot{
                        contains
                        exact
                        tipo
                        response
                        apiURL
                        bData
                }

                     noMatch
                }
  `*/
