import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {ChatService} from 'app/main/apps/chat/chat.service';
import {isEmpty} from 'lodash';

@Component({
  selector: 'chat-view3',
  templateUrl: './chat-view3.component.html',
  styleUrls: ['./chat-view3.component.scss']
})
export class ChatView3Component implements OnInit {
  cliente:any
  contact:any
  agente:any
  chat:any
  searchText=""
  showSearch=false
 
  constructor(
    public dialogRef: MatDialogRef<ChatView3Component>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public datepipe: DatePipe,
    public _chatService: ChatService
  ) { 
    //console.log("Me llego esto: ",this.data)
    if (!this.data.buscar){
    this.contact=this.data.phone;
    this.cliente=this.data.lic;
    this.agente=this.data.operador;
    this.chat=this.data.msgs? this.data.msgs:[this.data];
    }else{
      //console.log("buscar")
      this.agente=this.data.buscar.agente_exc
      this.cliente=this.data.buscar.cuenta
      this.contact=this.data.buscar.contacto
      this.buscarChat(this.data.buscar)
    }
  }

  ngOnInit() {
  }

esBotonThomas(Mensaje: string) { 
        try {
            if (Mensaje) {
              if (Mensaje.slice(0,1) == '{') {
                if ( typeof JSON.parse(Mensaje) == "object") {
                    return true
                } else {
                    return false
                }
              } else {
                  return false
              }
                    
            }
        } catch (error) {
          
        }
  }

  jsonParse(value: string) {
    return JSON.parse(value);
  }

  buscarChat(data) {
    //console.log("buscarChat",data)
    this._chatService.GetChatByLicandPhone(data.contacto, data.cuenta).then((res:any)=>{
      //console.log("res",res)
      if(isEmpty(res)) return
      let allChat=[]

      res.forEach(mensaje => {
        allChat.push(this.setChat(mensaje))
      });
      this.chat=allChat
      
    })
  }

  setChat(chat){
    let newChat:any={
      /*
       {
        "who": "584244777314@c.us",
        "contenType": "0",
        "message": "",
        "time": 1639585782,
        "me_to": "o",
        "idEmpresa": "prueba123456",
        "phone": "584244777314@c.us",
        "idConversacion": "584121311690_584244777314_2021_12-15",
        "idmessage": "false_584244777314@c.us_09E514EE52FC4A5A8305197E4B3D2EC2_2",
        "timestamp": 1639585782,
        "TimeRead": "15/12/21 12:29 p. m.",
        "status": 0,
        "statusc": 0,
        "readmsg": false,
        "operador": "carlos",
        "lic": "584121311690@c.us",
        "etiqueta": " "
    },
        */
    }

    newChat.who=chat.phone
    newChat.contenType=chat.contentType
    newChat.message=chat.content
    newChat.time=chat.TimeRead
    newChat.me_to=chat.me_to
    newChat.idEmpresa=chat.idEmpresa
    newChat.phone=chat.phone
    newChat.idConversacion=chat.idConversacion
    newChat.idmessage=chat.idmessage
    newChat.timestamp=chat.timestamp
    newChat.TimeRead=this.convertTimeStamp(chat.TimeRead)
    newChat.status=chat.status
    newChat.statusc=chat.statusc
    newChat.readmsg=chat.readmsg
    newChat.operador=chat.operador
    newChat.lic=chat.lic
    newChat.etiqueta=chat.etiqueta || ""

    console.log(newChat.TimeRead);
    
    return newChat
  }

  convertTimeStamp(data: string): string{
    const date = parseInt(data)
    const result = new Date(date);
    let formattedDate = this.datepipe.transform(result, 'short');
    return formattedDate;
  }

}
