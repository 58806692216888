import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Observer } from 'rxjs';
 

import * as io from 'socket.io-client';
import { Message } from './model/message';
import { Event } from './model/event';
import { Iinvoices } from './model/invoice';

const SERVER_URL = 'wss://apitest5.thomas-talk.me'; //https://apitest2.thomas-talk.me/

@Injectable()
export class SocketService {
    private socket;

    //Comentado mientras se implementa canales 
     public initSocket(): void { 
        console.log("Socket Connect")
        this.socket = io(SERVER_URL); 
    }

    public finalysocket() : void {
        console.log("Me voy a desconectar")
        this.socket.close()
    }

    public send(message: Message): void {
        console.log("Me llego esto")
        console.log(message)
        this.socket.emit('notification', message);
    }

    public onMessage(): Observable<Iinvoices> {
        return new Observable<Iinvoices>(observer => {
            this.socket.on('notification', (data: Iinvoices) => {
                observer.next(data)
            }); 
        });
    }

    public onEvent(event: Event): Observable<any> {
        return new Observable<Event>(observer => {
            console.log('onEvent del Socket' );
            console.log(observer );
            this.socket.on(event, () => observer.next());
        });
    } 
}
