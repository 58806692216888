import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard-card',
  templateUrl: './dashboard-card.component.html',
  styleUrls: ['./dashboard-card.component.scss']
})
export class DashboardCardComponent implements OnInit {

  @Input() title: any
  @Input() subtitle: any
  @Input() dataCards: any
  @Input() Prefijo: string = 'Reportes.'
  Prefijoi18n: string = 'Chat.'
  Prefijoi: string = 'Generico.'
  panelOpenState = false;
  view: string;
  card9Expanded: boolean;
  card10Expanded: boolean;
  card19: any;
  card24: any;
  card25: any;
  card26: any;

  constructor(private router: Router ) {
    // Set the defaults
    this.view = 'preview';
    this.card9Expanded = false;
    this.card10Expanded = false;

  }

  test(data){
    this.router.navigate([data])
  }

  ngOnInit() {
  }
  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------




}
