import { HttpClient } from "@angular/common/http";
import { Apollo } from "apollo-angular";
import gql from "graphql-tag";

import { EventEmitter } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { ShowDataService } from '../services/global/show-data.service';

export class ContactsFakeDb {

    Token = sessionStorage.getItem('DataLogin')
    static Operador: any = {}

    Prefijo: String = 'Etiquetas.'
    static translate: any;
    constructor(private translate: TranslateService) { }
    public static Counting: EventEmitter<Number> = new EventEmitter();

    // Si se edita alguno de los campos aqui presente sobre todo menú
    // asi sea una mayúscula puede dañar el sistema, NO TOCAR MEJOR
    public static contactos = []



    public static contactos2 = []


    public static contacts = [
        {
            'id': '5725a680b3249760ea21de52',
            'name': 'Abbott',
            'lastName': 'Keitch',
            'avatar': 'assets/images/avatars/Velazquez.jpg',
            'code': '13457626',
            'company': 'Saois',
            'jobTitle': 'Digital Archivist',
            'email': 'abbott@withinpixels.com',
            'phone': '+1-202-555-0175',
            'address': '933 8th Street Stamford, CT 06902',
            'birthday': '',
            'notes': ''
        },
        {
            'id': '5725a680606588342058356d',
            'name': 'Arnold',
            'lastName': 'Matlock',
            'avatar': 'assets/images/avatars/Arnold.jpg',
            'code': '13457626',
            'company': 'Laotcone',
            'jobTitle': 'Graphic Artist',
            'email': 'arnold@withinpixels.com',
            'phone': '+1-202-555-0141',
            'address': '906 Valley Road Michigan City, IN 46360',
            'birthday': '',
            'notes': ''
        },
        {
            'id': '5725a68009e20d0a9e9acf2a',
            'name': 'Barrera',
            'lastName': 'Bradbury',
            'avatar': 'assets/images/avatars/Barrera.jpg',
            'code': '13457626',
            'company': 'Unizim',
            'jobTitle': 'Graphic Designer',
            'email': 'barrera@withinpixels.com',
            'phone': '+1-202-555-0196',
            'address': '183 River Street Passaic, NJ 07055',
            'birthday': '',
            'notes': ''
        },
        {
            'id': '5725a6809fdd915739187ed5',
            'name': 'Blair',
            'lastName': 'Strangeway',
            'avatar': 'assets/images/avatars/Blair.jpg',
            'code': '13457626',
            'company': 'Conedubdax',
            'jobTitle': 'Visual Designer',
            'email': 'blair@withinpixels.com',
            'phone': '+1-202-555-0118',
            'address': '143 Jones Street Eau Claire, WI 54701',
            'birthday': '',
            'notes': ''
        },
        {
            'id': '5725a68007920cf75051da64',
            'name': 'Boyle',
            'lastName': 'Winters',
            'avatar': 'assets/images/avatars/Boyle.jpg',
            'code': '13457626',
            'company': 'Newo',
            'jobTitle': 'Catalogue Illustrator',
            'email': 'boyle@withinpixels.com',
            'phone': '+1-202-555-0177',
            'address': '218 Pearl Street Brandon, FL 33510',
            'birthday': '',
            'notes': ''
        },
        {
            'id': '5725a68031fdbb1db2c1af47',
            'name': 'Christy',
            'lastName': 'Camacho',
            'avatar': 'assets/images/avatars/Christy.jpg',
            'code': '13457626',
            'company': 'uniway',
            'jobTitle': '3D Animator',
            'email': 'christy@withinpixels.com',
            'phone': '+1-202-555-0136',
            'address': '329 Bridge Street Desoto, TX 75115',
            'birthday': '',
            'notes': ''
        },
        {
            'id': '5725a680bc670af746c435e2',
            'name': 'Copeland',
            'lastName': 'Redcliff',
            'avatar': 'assets/images/avatars/Copeland.jpg',
            'code': '13457626',
            'company': 'Tempron',
            'jobTitle': 'Multimedia Artist',
            'email': 'copeland@withinpixels.com',
            'phone': '+1-202-555-0107',
            'address': '956 6th Avenue North Bergen, NJ 0704',
            'birthday': '',
            'notes': ''
        },
        {
            'id': '5725a680e7eb988a58ddf303',
            'name': 'Estes',
            'lastName': 'Stevens',
            'avatar': 'assets/images/avatars/Estes.jpg',
            'code': '13457626',
            'company': 'nam-dex',
            'jobTitle': 'Special Effects Artist',
            'email': 'estes@withinpixels.com',
            'phone': '+1-202-555-0113',
            'address': '664 York Street Cambridge, MA 02138',
            'birthday': '',
            'notes': ''
        },
        {
            'id': '5725a680dcb077889f758961',
            'name': 'Harper',
            'lastName': 'MacGuffin',
            'avatar': 'assets/images/avatars/Harper.jpg',
            'code': '13457626',
            'company': 'runcane',
            'jobTitle': 'Application Developer',
            'email': 'harper@withinpixels.com',
            'phone': '+1-202-555-0173',
            'address': '738 Route 11 Cornelius, NC 28031',
            'birthday': '',
            'notes': ''
        },
        {
            'id': '5725a6806acf030f9341e925',
            'name': 'Helen',
            'lastName': 'Sheridan',
            'avatar': 'assets/images/avatars/Helen.jpg',
            'code': '13457626',
            'company': 'Subhow',
            'jobTitle': 'Content Developer',
            'email': 'helen@withinpixels.com',
            'phone': '+1-202-555-0163',
            'address': '194 Washington Avenue Saint Petersburg, FL 33702',
            'birthday': '',
            'notes': ''
        },
        {
            'id': '5725a680ae1ae9a3c960d487',
            'name': 'Henderson',
            'lastName': 'Cambias',
            'avatar': 'assets/images/avatars/Henderson.jpg',
            'code': '13457626',
            'company': 'Howcom',
            'jobTitle': 'Web Designer',
            'email': 'henderson@withinpixels.com',
            'phone': '+1-202-555-0151',
            'address': '686 Roosevelt Avenue Oviedo, FL 32765',
            'birthday': '',
            'notes': ''
        },
        {
            'id': '5725a680b8d240c011dd224b',
            'name': 'Josefina',
            'lastName': 'Lakefield',
            'avatar': 'assets/images/avatars/Josefina.jpg',
            'code': '13457626',
            'company': 'Gecko',
            'jobTitle': 'Web Developer',
            'email': 'josefina@withinpixels.com',
            'phone': '+1-202-555-0160',
            'address': '202 Hartford Road Lynchburg, VA 24502',
            'birthday': '',
            'notes': ''
        },
        {
            'id': '5725a68034cb3968e1f79eac',
            'name': 'Katina',
            'lastName': 'Bletchley',
            'avatar': 'assets/images/avatars/Katina.jpg',
            'code': '13457626',
            'company': 'Lexicom',
            'jobTitle': 'Software Designer',
            'email': 'katina@withinpixels.com',
            'phone': '+1-202-555-0186',
            'address': '219 Woodland Road Valrico, FL 33594',
            'birthday': '',
            'notes': ''
        },
        {
            'id': '5725a6801146cce777df2a08',
            'name': 'Lily',
            'lastName': 'Peasegood',
            'avatar': 'assets/images/avatars/Lily.jpg',
            'code': '13457626',
            'company': 'zooflex',
            'jobTitle': 'Software Specialist',
            'email': 'lily@withinpixels.com',
            'phone': '+1-202-555-0115',
            'address': '305 Willow Drive Superior, WI 54880',
            'birthday': '',
            'notes': ''
        },
        {
            'id': '5725a6808a178bfd034d6ecf',
            'name': 'Mai',
            'lastName': 'Nox',
            'avatar': 'assets/images/avatars/Mai.jpg',
            'code': '13457626',
            'company': 'quadzone',
            'jobTitle': 'Software Engineer',
            'email': 'mai@withinpixels.com',
            'phone': '+1-202-555-0199',
            'address': '148 Heather Lane Mcminnville, TN 37110',
            'birthday': '',
            'notes': ''
        },
        {
            'id': '5725a680653c265f5c79b5a9',
            'name': 'Nancy',
            'lastName': 'Jaggers',
            'avatar': 'assets/images/avatars/Nancy.jpg',
            'code': '13457626',
            'company': 'Opetamnix',
            'jobTitle': 'Software Architect',
            'email': 'nancy@withinpixels.com',
            'phone': '+1-202-555-0120',
            'address': '345 Laurel Lane Union City, NJ 07087',
            'birthday': '',
            'notes': ''
        },
        {
            'id': '5725a680bbcec3cc32a8488a',
            'name': 'Nora',
            'lastName': 'Franklin',
            'avatar': 'assets/images/avatars/Nora.jpg',
            'code': '13457626',
            'company': 'Saoway',
            'jobTitle': 'Database Coordinator',
            'email': 'nora@withinpixels.com',
            'phone': '+1-202-555-0172',
            'address': '572 Rose Street Summerfield, FL 34491',
            'birthday': '',
            'notes': ''
        },
        {
            'id': '5725a6803d87f1b77e17b62b',
            'name': 'Odessa',
            'lastName': 'Goodman',
            'avatar': 'assets/images/avatars/Odessa.jpg',
            'code': '13457626',
            'company': 'transace',
            'jobTitle': 'Database Administration Manager',
            'email': 'odessa@withinpixels.com',
            'phone': '+1-202-555-0190',
            'address': '527 Jefferson Court Conyers, GA 30012',
            'birthday': '',
            'notes': ''
        },
        {
            'id': '5725a680fb65c91a82cb35e2',
            'name': 'Trevino',
            'lastName': 'Bush',
            'avatar': 'assets/images/avatars/Trevino.jpg',
            'code': '13457626',
            'company': 'Dalthex',
            'jobTitle': 'Photojournalist',
            'email': 'trevino@withinpixels.com',
            'phone': '+1-202-555-0138',
            'address': '84 Valley View Road Norman, OK 73072',
            'birthday': '',
            'notes': ''
        },
        {
            'id': '5725a68018c663044be49cbf',
            'name': 'Tyson',
            'lastName': 'Marshall',
            'avatar': 'assets/images/avatars/Tyson.jpg',
            'code': '13457626',
            'company': 'Geocon',
            'jobTitle': 'Manuscript Editor',
            'email': 'tyson@withinpixels.com',
            'phone': '+1-202-555-0146',
            'address': '204 Clark Street Monsey, NY 10952',
            'birthday': '',
            'notes': ''
        },
        {
            'id': '5725a6809413bf8a0a5272b1',
            'name': 'Velazquez',
            'lastName': 'Smethley',
            'avatar': 'assets/images/avatars/Velazquez.jpg',
            'code': '13457626',
            'company': 'ranex',
            'jobTitle': 'Publications Editor',
            'email': 'velezquez@withinpixels.com',
            'phone': '+1-202-555-0146',
            'address': '261 Cleveland Street Riverside, NJ 08075',
            'birthday': '',
            'notes': ''
        }
    ];

    public static user = [
        {
            'id': '5725a6802d10e277a0f35724',
            'name': 'John Doe',
            'avatar': 'assets/images/avatars/profile.jpg',
            'starred': [
                '5725a680ae1ae9a3c960d487',
                '5725a6801146cce777df2a08',
                '5725a680bbcec3cc32a8488a',
                '5725a680bc670af746c435e2',
                '5725a68009e20d0a9e9acf2a'
            ],
            'frequentContacts': [
                '5725a6809fdd915739187ed5',
                '5725a68031fdbb1db2c1af47',
                '5725a680606588342058356d',
                '5725a680e7eb988a58ddf303',
                '5725a6806acf030f9341e925',
                '5725a68034cb3968e1f79eac',
                '5725a6801146cce777df2a08',
                '5725a680653c265f5c79b5a9'
            ],
            'groups': [
                {
                    'id': '5725a6802d10e277a0f35739',
                    'name': 'Friends',
                    'contactIds': [
                        '5725a680bbcec3cc32a8488a',
                        '5725a680e87cb319bd9bd673',
                        '5725a6802d10e277a0f35775'
                    ]
                },
                {
                    'id': '5725a6802d10e277a0f35749',
                    'name': 'Clients',
                    'contactIds': [
                        '5725a680cd7efa56a45aea5d',
                        '5725a68018c663044be49cbf',
                        '5725a6809413bf8a0a5272b1',
                        '5725a6803d87f1b77e17b62b'
                    ]
                },
                {
                    'id': '5725a6802d10e277a0f35329',
                    'name': 'Recent Workers',
                    'contactIds': [
                        '5725a680bbcec3cc32a8488a',
                        '5725a680653c265f5c79b5a9',
                        '5725a6808a178bfd034d6ecf',
                        '5725a6801146cce777df2a08'
                    ]
                }
            ]
        }
    ];

    public static CargarContactos(Token: string) {
        return this.contactos
    }

    /* public static ContactsByCliente(apollo: Apollo, Token: string, Data: any, _httpClient: HttpClient) {
        let DataContactos: any[] = []
        let { limit, page } = Data
        let data = apollo.watchQuery<any>({
            query: ConsultaContactosByCliente,
            variables: {
                token: Token,
                limit: limit,
                page: page
            }
        })
            .valueChanges
            .subscribe((result) => {
                let DataContactosByCliente = result.data.ContactosByEmpresa //Guardo la data obtenida
                let Totalcontactos = 0


                DataContactosByCliente.forEach(async res => {
                    const lic = parseInt(res.Licencia)

                    const DateContactoscliente = {
                        item: { id: lic },
                        id: stringify(lic),
                        lic: stringify(lic),
                        idCliente: res.idCliente,
                        columOLD: ['avatar', 'name', 'phone', 'fecha', 'Canal', 'menú'],
                        colum: [
                            { colum: 'avatar', type: 'avatar', head: 'Avatar', visible: true, isModelProperty: true },
                            { colum: 'name', type: 'string', head: 'Nombre', visible: true, isModelProperty: true },
                            { colum: 'phone', type: 'string', head: 'Móvil', visible: true, isModelProperty: true },
                            { colum: 'fecha', type: 'string', head: 'Fecha', visible: true, isModelProperty: true },
                            { colum: 'Canal', type: 'img', head: 'Canal', visible: true, isModelProperty: true },
                            { colum: 'Menu', type: 'menu', head: 'Menú', visible: true }
                        ],
                        type: ['avatar', 'string', 'string', 'string', 'img', 'menu'],
                        TitleColumShow: ['avatar', 'string', 'string', 'img', 'menu'],
                        menu: ["Editar/edit", "Eliminar/delete", "Chat /chrome_reader_mode"],
                        clientes: await res.contenido
                    }
                    _httpClient.post('api/contactos2/' + lic, DateContactoscliente)
                        .subscribe((response: any) => { }, (Error) => console.error('Contactos-cliente' + Error));

                    const Token = sessionStorage.getItem('DataLogin')
                    const helper = new JwtHelperService();
                    const decodedToken = helper.decodeToken(Token);
                    
                    ContactsFakeDb.Operador.id = decodedToken.Data.idUser
                    ContactsFakeDb.Operador.name = decodedToken.Data.Nombre
                    ContactsFakeDb.Operador.idCliente = decodedToken.Data.idCliente
                    //*filtramos los contactos por el idCliente
                    let contactosFiltrados = await res.contenido.filter((contact: any) => ContactsFakeDb.Operador.idCliente.includes(contact.idCliente))
                    Totalcontactos += contactosFiltrados.length
                    
                    const DatosByLic = {
                        item: { id: lic },
                        id: stringify(lic),
                        lic: stringify(lic),
                        idCliente: res.idCliente,
                        title: 'Contactos ' + res.NameCliente,
                        TitleColumShow: ['avatar', 'name', 'phone', 'Fecha de creación', 'Canal'],
                        columns: ['avatar', 'name', 'phone', 'fecha', 'Canal', 'menú'],
                        colum: [
                            { colum: 'avatar', type: 'avatar', head: 'Avatar', visible: true, isModelProperty: true },
                            { colum: 'name', type: 'string', head: 'Nombre', visible: true, isModelProperty: true },
                            { colum: 'phone', type: 'string', head: 'Móvil', visible: true, isModelProperty: true },
                            { colum: 'fecha', type: 'string', head: 'Fecha', visible: true, isModelProperty: true },
                            { colum: 'Canal', type: 'img', head: 'Canal', visible: true, isModelProperty: true },
                            { colum: 'Menu', type: 'menu', head: 'Menú', visible: true }
                        ],

                        columnsResum: ['avatar', 'name', 'phone', 'Canal', 'menú'],
                        type: ['avatar', 'string', 'string', 'string', 'img', 'menu'],
                        typeResum: ['avatar', 'string', 'string', 'img', 'menu'],
                        menu: ["Editar/edit", "Chat/chrome_reader_mode"], 
                        'rows': await contactosFiltrados
                    }

                    _httpClient.post('api/contactos/' + lic, DatosByLic).subscribe((response: any) => { }, (Error) => { console.error('Contactos'); console.error(Error) });
                });


            }, (err) => {
                console.error(err);
            });
        return this.contactos
    } */

    public static AllContacts(apollo: Apollo, Token: string, _httpClient: HttpClient) {
        let data = apollo.watchQuery<any>({
            query: ContactsClient,
            variables: {
                token: Token
            }
        })
            .valueChanges
            .subscribe(async (result) => {

                try {

                    let DataContactos = result.data.ContactosByCliente //Guardo la data obtenida
                    let TotalContact: number = 0

                    DataContactos.forEach(async res => {
                        const lic = parseInt(res.Licencia)
                        TotalContact = TotalContact + res.contenido.length

                        const DateContactoscliente = {
                            item: { id: lic },
                            id: JSON.stringify(lic),
                            lic: JSON.stringify(lic),
                            idCliente: res.idCliente,
                            columOLD: ['avatar', 'name', 'phone', 'fecha', 'Canal', 'menú'],
                            colum: [
                                { colum: 'avatar', type: 'avatar', head: 'Avatar', visible: true, isModelProperty: true },
                                { colum: 'name', type: 'string', head: 'Nombre', visible: true, isModelProperty: true },
                                { colum: 'phone', type: 'string', head: 'Móvil', visible: true, isModelProperty: true },
                                { colum: 'fecha', type: 'string', head: 'Fecha', visible: true, isModelProperty: true },
                                { colum: 'Canal', type: 'img', head: 'Canal', visible: true, isModelProperty: true },
                                { colum: 'Menu', type: 'menu', head: 'Menú', visible: true }
                            ],
                            type: ['avatar', 'string', 'string', 'string', 'img', 'menu'],
                            TitleColumShow: ['avatar', 'string', 'string', 'img', 'menu'],
                            menu: ["Editar/edit", "Eliminar/delete", "Chat /chrome_reader_mode"],
                            clientes: await res.contenido
                        }
                        _httpClient.post('api/contactos2/' + lic, DateContactoscliente)
                            .subscribe((response: any) => { }, (Error) => console.error('Contactos-cliente' + Error));

                        const DatosByLic = {
                            item: { id: lic },
                            id: JSON.stringify(lic),
                            lic: JSON.stringify(lic),
                            idCliente: res.idCliente,
                            title: 'Contactos ' + res.NameCliente,
                            TitleColumShow: ['avatar', 'name', 'phone', 'Fecha de creación', 'Canal'],
                            columns: ['avatar', 'name', 'phone', 'fecha', 'Canal', 'menú'],
                            colum: [
                                { colum: 'avatar', type: 'avatar', head: 'Avatar', visible: true, isModelProperty: true },
                                { colum: 'name', type: 'string', head: 'Nombre', visible: true, isModelProperty: true },
                                { colum: 'phone', type: 'string', head: 'Móvil', visible: true, isModelProperty: true },
                                { colum: 'fecha', type: 'string', head: 'Fecha', visible: true, isModelProperty: true },
                                { colum: 'Canal', type: 'img', head: 'Canal', visible: true, isModelProperty: true },
                                { colum: 'Menu', type: 'menu', head: 'Menú', visible: true }
                            ],

                            columnsResum: ['avatar', 'name', 'phone', 'Canal', 'menú'],
                            type: ['avatar', 'string', 'string', 'string', 'img', 'menu'],
                            typeResum: ['avatar', 'string', 'string', 'img', 'menu'],
                            menu: ["Editar/edit", "Chat/chrome_reader_mode"],
                            'rows': await res.contenido
                        }

                        _httpClient.post('api/contactos/' + lic, DatosByLic).subscribe((response: any) => { }, (Error) => { console.error('Contactos'); console.error(Error) });
                        this.Counting.emit(TotalContact);

                    });

                    //this.Counting.emit(TotalContact)

                } catch (error) {
                    console.error(error)
                }


            }, (err) => {
                console.error(err);
            });

    }

    public static ContactsByPhone(apollo: Apollo, Phone: string, _httpClient: HttpClient) {
        let data = apollo.watchQuery<any>({
            query: ConsultaContactosByPhone,
            variables: {
                Phone: Phone
            }
        })
            .valueChanges
            .subscribe((result) => {
                let DataContactosByPhone = result.data.ContactosByPhone //Guardo la data obtenida
            });
        return this.contactos
    }

    public CreateContact(apollo: Apollo, Data: any, _httpClient: HttpClient) {
        apollo.mutate({
            mutation: CreateContact,
            variables: {
                name: Data.name,
                avatar: Data.avatar,
                code: Data.code,
                extra: Data.extra,
                category: Data.category,
                status: Data.status,
                phone: Data.phone,
                canal: Data.canal
            }
        }).subscribe(({ data }) => {

        }, (error) => {
            console.error('there was an error sending the query', error);
        });
    }

    public UpdateContact(apollo: Apollo, Data: any, _httpClient: HttpClient) {
        apollo.mutate({
            mutation: UpdateContact,
            variables: {
                name: Data.name,
                avatar: Data.avatar,
                code: Data.code,
                extra: Data.extra,
                category: Data.category,
                status: Data.status,
                phone: Data.phone,
                canal: Data.canal
            }
        }).subscribe(({ data }) => {

        }, (error) => {
            console.error('there was an error sending the query', error);
        });
    }

}

export const ConsultaContactosByCliente = gql`
query ContactosByEmpresa($token:String, $limit:Int, $page:Int) {
    ContactosByEmpresa(
        token:$token
        limit:$limit
        page:$page
    ){
        Licencia
        NameCliente  
        logo
        idCliente
        contenido{
            id
            idCliente
            idEmpresa
            idCanal
            name
            lastName
            avatar
            code
            extra
            fecha
            category
            status
            phone
            Canal
            email
            company
            birthday
            address
            notes
        }
    }
  }
`;

export const ContactsClient = gql`
query ContactosByCliente($token:String){
    ContactosByCliente(token:$token){
        Licencia
        logo
        NameCliente
        idCliente
        contenido{
            id
            idCliente
            idEmpresa
            idCanal
            name
            lastName
            avatar
            code
            extra
            fecha
            category
            status
            phone
            Canal
            email
            company
            birthday
            address
            notes
            campos
        }
    }  
  }
`;

export const ConsultaContactosByPhone = gql`
query ContactosByPhone($Phone:String) {
    ContactosByPhone(Phone:$Phone){
        name
        avatar
        code
        extra
        fecha
        category
        status
        phone
        Canal
    }
  }
`;

export const CreateContact = gql`
mutation CreateContact($name:String,$avatar:String,$code:String,$extra:String,$category:String,$status:Boolean,$phone:String,$canal:String){
    CreateContact(input:{
        name:$name
        avatar:$avatar
        code:$code
        extra:$extra
        category:$category
        status:$status
        phone:$phone
        Canal:$canal
        }
    ){
        name
        avatar
        code
        extra
        fecha
        category
        status
        phone
        Canal
    }
}
`

export const UpdateContact = gql`
mutation UpdateContact($name:String,$avatar:String,$code:String,$extra:String,$category:String,$status:Boolean,$phone:String,$canal:String){
    UpdateContact(
        Data:{
            name:$name
            avatar:$avatar
            code:$code
            extra:$extra
            category:$category
            status:$status
            phone:$phone
            Canal:$canal
        }
    ){
        name
        avatar
        code
        extra
        fecha
        category
        status
        phone
        Canal
    }
}
`