import { HttpClient } from "@angular/common/http";
import { EventEmitter } from "@angular/core";
import { Apollo } from "apollo-angular";
import gql from "graphql-tag";

export class ProjectAgentesDb {

    public static Counting: EventEmitter<Boolean> = new EventEmitter();

    public static clear(apollo: Apollo, Token: string, _httpClient: HttpClient) {

        _httpClient.delete('api/project-dashboard-agentes/')
    }

    public static ChatAgentes = []

    public static agentes = []

    public static UsersOnline(apollo: Apollo, Token: string, _httpClient: HttpClient) {
        let DataContactos: any[] = []
        let data = apollo.watchQuery<any>({
            query: ConsultaUsersOnline,
            variables: {
                token: Token
            }
        })
            .valueChanges
            .subscribe((result) => {
                try {

                    if (!result.data.UsersOnline) {
                        return;
                    }
                    let DataUsers = result.data.UsersOnline //Guardo la data obtenida

                    DataUsers.forEach(async (res: any, index: number) => {
                        const lic = parseInt(res.Licencia)

                        const DataUserByCliente = {
                            item: { id: lic },
                            id: JSON.stringify(lic),
                            lic: JSON.stringify(lic),
                            'title': 'Agentes On-Line',
                            columns: [
                                { colum: 'avatar', type: 'avatar', head: 'Avatar', visible: false, isModelProperty: false },
                                { colum: 'name', type: 'string', head: 'Nombre', visible: true, isModelProperty: true },
                                { colum: 'cola', type: 'string', head: 'Cola', visible: true, isModelProperty: true },
                                { colum: 'online', type: 'icon', head: 'Online', visible: true, isModelProperty: true },
                                { colum: 'menú', type: 'menu', head: 'Menú', visible: true }
                            ],
                            'type': ['avatar', 'string', 'string', 'icon', 'menu'],
                            'menu': [/* "Ver/pageview", */ "Cambiar Estado/beach_access", "Atendiendo/chat_bubble" /*, "Entrar en Cuenta/lock_open"*/],
                            'rows': res.Content
                        }

                        _httpClient.post('api/project-dashboard-agentes/' + lic, DataUserByCliente).subscribe((response: any) => { }, (Error) => { console.error('agentes'); console.error(Error) });

                        if (DataUsers.length - 1 == index) {
                            try {
                                this.Counting.emit(true)
                            } catch (error) {
                                this.Counting.emit(false)
                            }

                        }
                    });

                } catch (error) {
                    console.error(error)
                }

            });
    }

    public static ChatsUsersD(apollo: Apollo, Token: string, _httpClient: HttpClient) {
        let data = apollo.watchQuery<any>({
            query: ConsultaChatsUserDashboard,
            variables: {
                token: Token
            }
        })
            .valueChanges
            .subscribe((result) => {
                try {

                    if (!result.data.ChatByUserDashboard) {
                        return;
                    }
                    let ChatsUsers = result.data.ChatByUserDashboard //Guardo la data obtenida
            
                    ChatsUsers.forEach(async res => {
                        const Operator = parseInt(res.operador)

                        const Data = {
                            item: { id: Operator },
                            id: res.operador,
                            idOperador: res.operador,
                            'title': 'Chat Agentes',
                            colum: [
                                { colum: 'contacto', type: 'phone', head: 'Contacto', visible: true, isModelProperty: true },
                                { colum: 'type', type: 'string', head: 'Tipo', visible: true, isModelProperty: true },
                                { colum: 'cuenta', type: 'phone', head: 'Cuenta', visible: true, isModelProperty: true },
                                { colum: 'fecha_i', type: 'date', head: 'Fecha Inicio', visible: true, isModelProperty: true },
                                { colum: 'agente_exc', type: 'string', head: 'Agentes exclusivos', visible: true, isModelProperty: true },
                                { colum: 'requerimiento', type: 'string', head: 'Requerimiento', visible: true, isModelProperty: true },
                                { colum: 'mensajes', type: 'string', head: 'Mensajes', visible: true, isModelProperty: true },
                                { colum: 'menu', type: 'menu', head: 'Menú', visible: true },
                            ],
                            type: ['string', 'string', 'string', 'string', 'string', 'string', 'string', 'menu'],
                            menu: ["Chat/chrome_reader_mode", "Reasignar/swap_horiz"],
                            'rows': res.Chats
                        }


                        _httpClient.post('api/Chats-Agentes-Dashboard/' + res.operador, Data).subscribe((response: any) => { }, (Error) => { console.error('agentes'); console.error(Error) });
                    });


                } catch (error) {
                    console.error(error)
                }

            });
    }

    public CargarAgentes() {
        return ProjectAgentesDb.agentes
    }

}

export const ConsultaUsersOnline = gql`
query UsersOnline($token:String){
    UsersOnline(token:$token){
        Licencia
        Content{
            avatar
            name
            phone
            cola
            online
            iconReasig
            idioma
            Permisos
            Canales
            UltimaConexion
            status
            isMaster
            idUser
            idEmpresa
            idRol
            Apellido
            Email
            Clave
            Direccion
            TipoUsuario
            idCliente
            idHorario
        }
    }
  }
`;


export const ConsultaChatsUserDashboard = gql`
query ChatByUserDashboard($token:String){
    ChatByUserDashboard(token:$token){
        operador
        Chats{
            id
            contacto
            type
            cuenta
            fecha_i
            agente_exc
            requerimiento
            mensajes
            Msj{
                idEmpresa
                phone
                idConversacion
                idmessage
                timestamp
                me_to
                contentType
                content
                TimeRead
                status
                statusc
                readmsg
                operador
                lic
                tag 
                etiqueta
            }
        }
    }
  }
`;
