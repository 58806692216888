import { Injectable, AfterContentInit, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { FuseUtils } from '@fuse/utils';
import { LogService } from 'app/services/global/Log.service'

import { ChatFakeDb } from 'app/fake-db/chat';
import { Apollo } from 'apollo-angular';
import { reject } from 'lodash';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
    providedIn: 'root'
})
export class ChatService implements AfterContentInit {

    public CargaChar: EventEmitter<boolean> = new EventEmitter();
    contacts: any[];
    chats: any[];
    user: any;
    onChatSelected: BehaviorSubject<any>;
    onContactSelected: BehaviorSubject<any>;
    onChatsUpdated: Subject<any>;
    onUserUpdated: Subject<any>;
    onLeftSidenavViewChanged: Subject<any>;
    onRightSidenavViewChanged: Subject<any>;

    private ChatActivo: any = {}

    Token = sessionStorage.getItem('DataLogin')
    await: any;
    YaTengoTodo: Boolean = false

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private ChatFDb: ChatFakeDb,
        private apollo: Apollo,
        private _RegistrarLog: LogService
    ) {
        // Set the defaults
        this.onChatSelected = new BehaviorSubject(null);
        this.onContactSelected = new BehaviorSubject(null);
        this.onChatsUpdated = new Subject();
        this.onUserUpdated = new Subject();
        this.onLeftSidenavViewChanged = new Subject();
        this.onRightSidenavViewChanged = new Subject();


        if (ChatFakeDb.YacargueChats){
        console.log('YacargueChats if: ', ChatFakeDb.YacargueChats)
                this.cargarTodo()
                this.YaTengoTodo = true
        }else{
        console.log('YacargueChats else: ', ChatFakeDb.YacargueChats)
        setTimeout(() => {
            if (!this.YaTengoTodo) {
                this.cargarTodo()
                this.YaTengoTodo = true;
                // console.log("enviroment Ya tengo Todo")
            }
        }, 45000)
        }

    }

    reloadChat() {

        ChatFakeDb.chatsReady.subscribe(() => {
            this.cargarTodo()
                .then(() => {
                    this.YaTengoTodo = true
                })
        },
        (err)=>{console.error(err)})




        /*
            return new Promise((resolve, reject) => {
                setTimeout(resolve, 30, 'foo');
            }).then( () => {
                new Promise<void>( (resolve, reject) => {
                
                });
            }
            )
        */
    }

    async cargarTodo() {
        return Promise.all<[any, any, any]>([
            this.getContacts(),
            this.getChats(),
            this.getUser(),

        ]).then(
            ([contacts, chats, user]) => {
                this.contacts = contacts;
                this.chats = chats;
                this.user = user;
                this.CargaChar.emit(true)
            },
        ).catch((error)=>{console.error(error)});
    }

    ngAfterContentInit() {
    }


    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        const helper = new JwtHelperService();
        const decodedToken = helper.decodeToken(this.Token);
        //TODO cambiar esto cuando se tenga el orden de carga 
        this.reloadChat()
    }


    /**
     * Get chat
     *
     * @param contactId
     * @returns {Promise<any>}
     */
    getChat(contactId, lic): Promise<any> {
        if (!lic) {
            return
        }
        const chatItem = this.user.chatList.find((item) => {
            return item.contactId === contactId && item.lic == lic;
        });

        this.ChatActivo = { ...chatItem };
        // Create new chat, if it's not created yet.
        if (!chatItem) {
            this.createNewChat(contactId).then((newChats) => {
                this.getChat(contactId, lic);
            }).catch((error)=>{console.error("Error",error)});
            return;
        }

        return new Promise((resolve, reject) => {
            this._httpClient.get('api/chat-chats/' + chatItem.id) //consulta get 
                .subscribe((response: any) => {
                    const chat = response;

                    const chatContact = this.contacts.find((contact) => {
                        return contact.id == contactId;
                    });
                    const chatData = {
                        chatId: chat.id,
                        dialog: chat.dialog,
                        contact: chatContact
                    };
                    this.onChatSelected.next({ ...chatData });
                },
                (err)=>{console.error("Error",err)}, 
                reject);

        });


    }//Revisar esta funcion

    getChat2(contactId, lic): Promise<any> {
        try {
            return new Promise(async (resolve, reject) => {
                
                if (!lic) { reject('Lic es obligatoria') }
                const chatItem = await this.user.chatList.find((item) => {
                    return item.contactId === contactId && item.lic == lic
                });
                // Create new chat, if it's not created yet.
                if (!chatItem) {
                    await this.createNewChat(contactId).then((newChats) => {
                    console.log('createNewChat: ', contactId,newChats) 
                    });
                    resolve("NewChat");
                }

                if(!chatItem) return
                await this._httpClient.get('api/chat-chats/' + chatItem.id) //consulta get con async await
                    .subscribe((response: any) => {
                        const chat = response;
                        this.contacts=this.contacts.filter(Boolean)
                        const chatContact = this.contacts.find((contact) => {
                            return contact.id == contactId;
                        });
                        const chatData = {
                            chatId: chat.id,
                            dialog: chat.dialog,
                            contact: chatContact
                        };

                        this.onChatSelected.next({ ...chatData });
                        resolve(chatData);
                    },
                    (err)=>{console.error("Error",err)},
                     reject);
            })

        } catch (error) {
            this._RegistrarLog.RegistrarLog(error);
            reject(error);
        }
    }

    /**
     * Create new chat
     *
     * @param contactId
     * @returns {Promise<any>}
     */
    createNewChat(contactId): Promise<any> {
         console.log('createNewChat: ', contactId)
        try {
            return new Promise((resolve, reject) => {

                const contact = this.contacts.find((item) => {
                    /*                 console.log('asi esta contacts en createnewchat: ', item) */
                    return item.id === contactId;
                });
                console.log("Contact en createNewChat: ",contact)
                let date= new Date();
                //!Aqui se genera el id como debe deser
                const chatId = FuseUtils.generateGUID();
                let idConversacion= `${contact.idCanal[0].replace('@c.us', '')}_${contact.phone.replace('@c.us', '')}_${date.getFullYear()}_${date.getMonth() + 1}-${date.getDate()}` // preguntar

                const chat = {
                    id: idConversacion,//chatId,
                    dialog: []
                };
                // console.log(contact)

                const chatListItem = {
/*                     contactId: "584128162164",
                    id: contact.phone,
                    lastMessageTime: new Date().toISOString(),
                    name: contact.name,
                    unread: null,
                    lic:"584120202020@c.us" */
                    "id": idConversacion,
                    "idEmpresa": contact.idEmpresa,
                    "lic": contact.idCanal[0],
                    "contactId": contact.phone,
                    "name": contact.name,
                    "contenType": "0",
                    "unread": 0,
                    "lastMessage": "Nueva Conversacion",
                    "lastMessageTime": date.toISOString(),
                    "canal": "assets/icons/Whatsapp.png",
                    "etiqueta": "",
                };

                // Add new chat list item to the user's chat list
                this.user.chatList.push(chatListItem);


                // Post the created chat
                this._httpClient.post('api/chat-chats', { ...chat })
                    .subscribe((response: any) => {

                        // Post the new the user data
                        this._httpClient.post('api/chat-user/' + this.user.id, this.user)
                            .subscribe(newUserData => {

                                // Update the user data from server
                                this.getUser().then(updatedUser => {
                                    this.onUserUpdated.next(updatedUser);
                                    resolve(updatedUser);
                                });
                            });
                    },(err)=>{console.error("Error", err)} ,
                    reject);
            });

        } catch (error) {
            this._RegistrarLog.RegistrarLog(error);
            reject(error);
        }

    }

    updateChats(chat: any) {
        this._httpClient.post('api/chat-chats', { ...chat })
            .subscribe((response: any) => {
                this.chats.push(response);
            }
            ,(err)=>{console.error("Error",err)});
    }

    /**
     * Select contact
     *
     * @param contact
     */
    selectContact(contact): void {
        /*         console.log('selectContact: ', contact) */
        this.onContactSelected.next(contact);
    }

    /**
     * Set user status
     *
     * @param status
     */
    setUserStatus(status): void {
        //console.log('setUserStatus: ', status)
        this.user.status = status;
    }

    /**
     * Update user data
     *
     * @param userData
     */
    updateUserData(userData): void {
        /*         console.log('updateUserData: ',userData) */
        this._httpClient.post('api/chat-user/' + this.user.id, userData)
            .subscribe((response: any) => {
                this.user = userData;
                /*                 console.log('asi quedo user: ', this.user) */
            }
            ,(err)=>{console.error("Error",err)});
    }

    /**
     * Update the chat dialog
     *
     * @param chatId
     * @param dialog
     * @returns {Promise<any>}
     */
    updateDialog(chatId, dialog): Promise<any> {
        //console.log('updateDialog: ', chatId, dialog)
        return new Promise((resolve, reject) => {

            const newData = {
                id: chatId,
                dialog: dialog
            };

            this._httpClient.post('api/chat-chats/' + chatId, newData)
                .subscribe(updatedChat => {
                    resolve(updatedChat);
                },
                (err)=>{console.error("Error",err)},
                reject);
        });
    }

    newContact(contact): Promise<any> {
        //console.log("getContacts()")
        return new Promise((resolve, reject) => {
            this._httpClient.post('api/chat-contacts/', contact)
                .subscribe((response: any) => {
                    /*                     console.log('this.contact: ',this.contacts)
                                        console.log('response: ',response) */
                    this.contacts.push(response)
                    resolve(response);
                },
                (err)=>{console.error("Error",err)},
                reject);
        });
    }


    /*
    *   TEST DE CONTACT
    *
    */

    getAll(): Promise<any> {
        // return new Promise((resolve, reject) => {
        //     Promise.all([
        //         this.getContacts(),
        //         this.getChats(),
        //         this.getUser()
        //     ]).then(
        //         ([contacts, chats, user]) => {
        //             this.contacts = contacts;
        //             this.chats = chats;
        //             this.user = user;
        //             console.log("getAll")
        //             resolve();
        //         },
        //         reject
        //     );
        // });
        //console.log("getContacts()")
        return new Promise((resolve, reject) => {
            try {
                Promise.all([this.getContacts(), this.getChats(), this.getUser()])
                    .then((result: any) => {
                        resolve(result);
                    })
                    .catch((err: any) => reject(err));
            } catch (error) {
                this._RegistrarLog.RegistrarLog(error);
                reject(error);
            }
        });
    }

    /**
     * Get contacts
     *
     * @returns {Promise<any>}
     */
    getContacts(): Promise<any> {
        //console.log("getContacts()")
        return new Promise((resolve, reject) => {
            try {
                this._httpClient.get('api/chat-contacts')
                    .subscribe((response: any) => {
                        resolve(response);
                    }, reject);
            } catch (error) {
                this._RegistrarLog.RegistrarLog(error);
                reject(error)
            }
        });
    }

    /**
     * Get chats
     *
     * @returns {Promise<any>}
     */
    getChats(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get('api/chat-chats')
                .subscribe((response: any) => {
                    resolve(response);
                },
                (err)=>{console.error("Error",err)},
                reject);
        });
    }

    getChatsPaginados() {
        ChatFakeDb.CargarChatsPaginados()
    }

    /**
     * Get user
     *
     * @returns {Promise<any>}
     */
    getUser(): Promise<any> {
        //console.log("getUser()")
        return new Promise((resolve, reject) => {
            this._httpClient.get('api/chat-user')
                .subscribe((response: any) => {
                    resolve(response[0]);
                }, 
                (err)=>{console.error("Error",err)},
                reject);
        });
    }

    GetChatByPhone(phone: String) {
        return new Promise((resolve, reject) => {
            try {

                this.ChatFDb.ChatsByPhones2(this.apollo, this.Token, phone).then((result: any) => {
                    resolve(result)
                })

            } catch (error) {
                this._RegistrarLog.RegistrarLog(error);
                reject(error);
                console.error('Error In GetChatByPhone ' + error)
            }
        });
    }

    GetChatHistoryByPhone(phone: String, page: number, limit: number) {
        return new Promise((resolve, reject) => {
            try {

                this.ChatFDb.ChatHistoryByPhones(this.apollo, this.Token, phone, page, limit).then((Result: any) => {
                    resolve(Result)
                })

            } catch (error) {
                console.error('Error In GetChatHistoryByPhone ' + error)
                this._RegistrarLog.RegistrarLog(error);
                reject(error)
            }
        });
    }

    GetChatByLicandPhone(phone: String,lic:String,) {
        console.log("GetChatByLicandPhone: ",phone,lic)
        return new Promise((resolve, reject) => {
            try {
                this.ChatFDb.chatbyLicAndPhone(this.apollo, this.Token, phone, lic).then((result: any) => {
                    resolve(result)
                })

            } catch (error) {
                this._RegistrarLog.RegistrarLog(error);
                reject(error);
                console.error('Error In GetChatByPhone ' + error)
            }
        });
    }

    updateChatActivo(data: any) {
        this.ChatActivo = { ...data };
        /*         console.log(this.chats)
                console.log(this.getChats()) */
        /*         console.log("update chat activo: ", this.ChatActivo) */
        /*
        //Ejemplo de la estructura
        canal: "assets/icons/Whatsapp.png"
        contactId: "51970868294@c.us"
        id: "801fcc3a-7e9b-3932-06fb-a9e3a25bdb18"
        idEmpresa: "prueba123456"
        lastMessage: "Buenas tardes, mi pago ya fue . . ."
        lastMessageTime: "1625163506072"
        lic: "51957052872@c.us"
        name: "🌼A D R I A N A"
        unread: 0
        */
    }

    updateEtiqueta(etiqueta: any) {
        //this.ChatActivo.etiqueta=etiqueta
        //console.log(this.user)
        let finded = this.user.chatList.find((chat) => chat.id == this.ChatActivo.id)
        //console.log(finded)
        if(finded) {
            finded.etiqueta = etiqueta
         }
        //console.log(this.user)
        this.updateUserData(this.user)
    }

    getChatActivo() {        
        return this.ChatActivo;
    }

    getChatEtiquetas() {
        try {
            if (this.ChatActivo.etiqueta != undefined) 
                if (typeof this.ChatActivo.etiqueta == "string") 
                    if (this.ChatActivo.etiqueta != "")  return this.ChatActivo.etiqueta.substring(0, 25) + "...";
                    else return ""            
                else return ""            
            else return ""   
        } catch (error) {
            return ""  
        }
             
    }

    GetTotalCola(Token:any) {
        return new Promise((resolve, reject) => {
            try {
                this.ChatFDb.MSJInCola(this.apollo, Token).then((res:any)=>{
                    //console.log("res in promise",res)
                    resolve(res)
                })
            } catch (error) {
                console.error(error)
            }
        })
    }
   
   //Para limitar el largo de los nombres en el chat 
  CheckName(name:any){
    let newName:any = name
/*     console.log(name) */
    try {
        if (name.length>20) {
            /*         console.log("si") */
                    if (name.includes(" ")){
            /*         console.log("Si tiene") */
                    newName = name.split(" ")
            /*         console.log(newName) */
                   newName= `${newName[0]} ${newName[1]}`
                    }else{
            /*         console.log("No tiene") */
                    newName = name.substring(0,20)
                    }
                } else {
                    newName = name
                }
            /*     console.log(newName) */
                    return newName
        
    } catch (error) {
        console.error("Error",error);
    }
    
  }

}

