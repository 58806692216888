import { Injectable } from "@angular/core";
var CryptoJS = require("crypto-js");
import { environment } from 'environments/environment.prod';

@Injectable({
    providedIn: "root"
})
export class SeguridadDatos {
    constructor() { }

    key: string = 'T0rNaDoK4tr1Na?RTgcNmhKU='

    //Encripta la los datos
    public encrypt(str: string) {
        let encrypted = CryptoJS.AES.encrypt(str, this.key, {
            keySize: 16,
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7,
        });
        return encrypted.toString();
    }

    private async EncrypObj(obj: any) {
        const newObj: any = {};
        for (let keyName in obj) {
            if (typeof obj[keyName] === "number") { obj[keyName] = obj[keyName].toString() }
            if (obj[keyName] instanceof Array || obj[keyName] instanceof Object) {
                obj[keyName] = this.EncrypObj(obj[keyName]);
            }
            else newObj[keyName] = String(this.encrypt(obj[keyName]));
        }
        return newObj;
    }

    private async EncrypArray(Data: any) {
        for (let index = 0; index < Data.length; index++) {
            Data[index] = await this.EncrypObj(Data[index])
            if (index === Data.length - 1) {
                return Data
            }
        }
    }

    // Encripta los datos que que deben ser enviados en una peticion
    public EncrypDataHash(Datos: any) {
        return new Promise((resolve, reject) => {
            try {
                if (Datos instanceof Array) {
                    resolve(this.EncrypArray(Datos));
                } else {
                    resolve(this.EncrypObj(Datos));
                }
                // Object.entries(Datos).forEach(([keyOriginal, valueKey]: any ,index:number) => {
                //     var Tamano= Object.keys(Datos);
                //     if(typeof valueKey !="number" && valueKey !=""&& valueKey !=undefined && valueKey !=null){
                //         const Encrypt = this.encrypt(valueKey); //Encripto
                //         Datos[keyOriginal] = Encrypt;
                //     }
                //     if(index == Tamano.length-1){
                //         resolve(Datos)
                //     }
                // })
            } catch (error) {
                reject(error);
            }
        })
    }

    Descryp(Datos: any) {
        let Data: any = CryptoJS.AES.decrypt(Datos, this.key, {
            keySize: 16,
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7,
        }).toString(CryptoJS.enc.Utf8);
        if (typeof Data == "string") { return JSON.parse(Data) } else { return Data }
    }

    // public encrypt_string(value: string,length: number ){

    //     const hash = createHash('sha1');
    //     const update_hash = hash.update(value);
    //     const hashed_value = update_hash.digest('hex');

    //     return hashed_value.toString();

    //     // const value_encrypted = CryptoJS.AES.encrypt(value, this.key, {
    //     //     keySize: length,
    //     //     mode: CryptoJS.mode.ECB,
    //     //     padding: CryptoJS.pad.Pkcs7,
    //     // });
    //     // return value_encrypted.toString();
    // }

    btoa(str: string) {
        return btoa(str)
    }
}