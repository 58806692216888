export const locale = {
    lang: 'en',
    data: {
        'NAV': {
            'APLICACIÓN': 'Applications',
            'DASHBOARDS': 'Dashboards',
            'ADMINISTRACION': 'Administration',
            'INTEGRADORES': 'Integrators',
            'CUSTOMFUNCTION': 'Custom Function',
            'CUSTOMIZE': 'Customize',
            'CALENDAR': 'Campaña',
            'ECOMMERCE': 'E-Commerce',
            'MAIL': {
                'TITLE': 'Mail',
                'BADGE': '25'
            },
            'MAIL_NGRX': {
                'TITLE': 'Mail Ngrx',
                'BADGE': '13'
            },
            'Control': 'Control',
            'CHAT': 'Chat',
            'Cliente': 'Client',
            'Agentes': 'Agents',
            'Billing': 'Billing',
            'FILE_MANAGER': 'Gallery',
            'Configuracion': 'Setting',
            'Preguntas': 'Questions',
            'Novedades': 'Novelties',
            'Entrenamiento': 'Training',
            'CONTACTS': 'Contacts',
            'TODO': 'To-Do',
            'REPORTES': 'Scrumboard'
        },
        Reportes: {
            'billingSection': 'Billing Section',
            'FacturasMasivas': 'Mass Invoices',
            'FacturasMasivasv2': 'Facturas Masivasv2',
            'AnularFacturas': 'Cancel Invoice',
            'Invoice_Generated': 'Invoice Generated',
            'Invoice_Consultation': 'Invoice Consultation',
            'Invoice_Consultationv2': 'Invoice Consultationv2',
            'Invoice_Reversal': 'Invoice Reversal',
            'FacturasMasivasDesc': 'Select to send mass billings',
            'Invoice_GeneratedDesc': 'Select to query generated invoices',
            'Invoice_ConsultationDesc': 'Select to consult invoices',
            'Invoice_ReversalDesc': 'Select to make cancellations on invoices',
            'Clientes': 'Clients',
            'Contactos': 'Contacts',
            'Search': 'Search for a',
            'Nombre': 'Name',
            'Avater': 'Avatar',
            'Agentes': 'Agent',
            'Estatus': 'Status',
            'Cola': 'On Hold',
            'Menú': 'Menu',
            'Agregar': 'Add',

            'avatar': 'avatar',
            'Avatar': 'Avatar',
            'name': 'Name',
            'phone': 'Phone',
            'Canal': 'Channel',
            'menú': 'Menu',

            'Roles': 'Roles',
            'Rol': 'Permissions',
            'Descripción': 'Description',
            'Acción': 'Action',

            'url': 'Url',
            'title': 'Title',
            'owner': 'Owner',
            'modified': 'Modified',
            'menu': 'Menu',

            'Galeria': 'Gallery',
            'Enter': 'Press intro',
            'Exportar': 'To Export',
            'NoData': 'No data available, select a file or enter a new data.',

            'Técnicos': 'Technicians',
            'Apellido': 'Firstname',
            'Teléfono': 'Phone',

            'BusquedaAvanzada': 'Advanced Search',
            'Contacto': 'Contact',
            'TextoDelMensaje': 'Text of the message',
            'TipoDeMessage': 'Type of message',
            'Telefono': 'Telephone',
            'Etiqueta': 'Label',
            'FechaDeIncio': 'Start Date',
            'FechaDeFin': 'End Date',
            'Entrante': 'Incoming',
            'Saliente': 'Outgoing',
            'Todos': 'All',
            'Generador': 'Generator',
            'Reportes': 'Reports',
            'TipoDeMensaje': 'Type of messages',
            'ContenidoMensaje': 'Content of message',
            'Buscar': 'Search',
            'Realizar nueva búsqueda': 'Perform a new search',
            'Encuestas': 'Surveys',
            'TipoDeEncuesta': 'Survey Type',
            'EncuestasAgentes': 'Surveys conducted by bot and agents.',
            'ReporteAgente': 'Filtered report of all clients by agent',

            'Texto': 'Text',
            'Imagen': 'Image',
            'Video': 'Video',
            'Audio': 'Audio',
            'PDF': 'PDF',
            'Cierre': 'Closing',
            'Transferir': 'Transfer',
            'Sticker': 'Sticker',

            'Consulta': 'The query must be in a maximum range of 2 months',
            'CanalBusqueda': 'You must provide a channel with which you must search',

            'Ciudad': 'City',
            'Email': 'Email',
            'Bravery Rating': 'Bravery Rating',
            'Fecha': 'Date',
            'First name': 'First name',
            'Michael': 'Michael',


            'Indicadores': 'IIndicators',
            'App': 'Fibex App',
            'Rendimiento': 'Performance',
            'ConteRendi': 'By means of indicators you can carry out a control of the performance of the Agents',
            'Tiempos': 'Times',
            'ConteTiempos': 'You will be able to see the connection time of the agents.',
            'Bots': 'FibexApp',
            'ConteBots': 'Reports of what the bots attend via WhatsApp.',
            'Fecha Inicial': 'Start date',
            'Fecha Final': 'End Date',
            'Operador': 'Agent',
            'Licencias': 'Licenses',
            'Etiquetas': 'Label',
            'Totalmensajes': 'Total messages',

            'Factura_Masiva': 'Mass Invoices',

            'FECHA': 'Date',
            'CODIGO': 'Code',
            'message': 'Mensaje de error',
            'CLIENTE': 'Customer',
            'FACTURA': 'Invoice',
            'CONTROL': 'Control',
            'BASE': 'Base',
            'IVA': 'VAT',
            'NETO': 'Net',
            'BASEUSD': 'Base $',
            'IVAUSD': 'VAT $',
            'NETOUSD': 'Net $',
            'DESCRIP': 'Description',
            'ART': 'Item',
            'DIRECCION': 'Address',
            'IGTF': 'IGTF',
            'IGTFUSD': 'IGTF $',
            'TASABCV': 'BCV Rate',
            'FECHATASA': 'Date BCV',
            'STATUS': 'Status',
            'FORMAPAGO': 'Form of Payment',
            'TIPOFACTURA': 'Invoice Type',
            'FACTURAFECTADA': 'Affected Invoice',
            'URLFACTURA': 'Invoice Url',
            'VARIOSITEM': 'Various Items',
            'ITEM': 'Items',
        },
        ReportList: {
            'Agentes': 'Agents',
            'Atajos': 'Shortcuts',
            'Abreviado': 'Abbreviated',
            'Texto': 'Text',
            'Canal': 'Channel',
            'Menú': 'Menu',
            'Móvil': 'Mobile',


            'Etiqueta': 'Tag',
            'Operador': 'Agent',
            'Total': 'Total',

            'Campos': 'Countryside',
            'Campo': 'Countryside',
            'Tipo': 'Type',
            'Requerido': 'Required',
            'Ubicación': 'Location',

            'Etiquetas': 'Labels',

            'Horarios': 'Schedule',
            'Descripción': 'Description',
            'Jornadas': 'Journeys',
            'DiaInicio': 'Beginning',
            'DiaFin': 'End',
            'Excepciones': 'Exceptions',

            'Contactos': 'Contacts',

            'Bot': 'Bot',

            'Avatar': 'Photo',
            'Nombre': 'Name',
            'Cola': 'Cola',
            'Online': 'Online',


            'Mensajes': 'Messages',
            'Facturas_Fallidas': 'Invoices Fails',
            'Facturas': 'Invoices',
            'Contacto': 'Contacto',
            'Cuenta': 'Account',
            'Fecha Inicio': 'Date / Start ',
            'Requerimiento': 'Requirement',
            'AgentesExclusivos': 'Exclusive agents',

            'Transferir': 'To transfer',
            'Ultima conexion': 'Last connection',

            'Exportar': 'To Export',
            'Agentes exclusivos': 'Exclusive agents',
            'Telefono': 'Phone',
            'Mensaje': 'Message',
            'message':'Mensaje de error',

            'Fecha': 'Date',
            'Codigo': 'Code',
            'Cliente': 'Customer',
            'Factura': 'Invoice',
            'Control': 'Control',
            'Base': 'Base',
            'IVA': 'VAT',
            'Neto': 'Net',
            'Descripcion': 'Description',
            'Articulo': 'Item',
            'Direccion': 'Address',

            'Reporte': 'Reports',
            'TipoContenido': 'Content Type',
            'Contenido': 'Content',
            'Mensaje de error': 'Mensaje de error',

            'nombre': 'Name',
            'tipoDeFalla': 'Type of Failure',
            'banco': 'Bank',
            'tipoDeUsuario': 'User Type',
            'imagenes': 'Images',
            'procesado': 'Processed',
            'phone': 'Phone',
            'Filtro': 'Filter',
            'Mesajes masivos': 'Mass messages',
            'Licencia': 'Licenses',
            'Totalmensajes': 'Total messages',
            'IGTF': 'IGTF',
            'IGTF $': 'IGTF$',
            'Tasa BCV': 'BCV rate',
            'Fecha BCV': 'BCV Date',
            'Estatus': 'Status',
            'Base $': 'Base$',
            'IVA $': 'VAT$',
            'Neto $': 'Net $',
            'Forma Pago': 'Payment Form',
            'Tipo Factura': 'Invoice Type',
            'Factura Afectada': 'Affected Invoice',
            'Url Factura': 'Url Invoice',
            'EXENTO': 'Exempt',
            'BASE IGTF':'IGTF Base'
        },
        Generico: {
            'Siguiente': 'Next',
            'SIGUIENTE': 'NEXT',
            'Seleccionar': 'To select',
            'Atras': 'Behind',
            'CANCELAR': 'CANCEL',
            'AGREGAR': 'ADD',
            'Aceptar': 'Accept',
            'GUARDAR': 'SAVE',
            'CARGANDO': 'LOADING',
            'CREAR HORARIO': 'CREATE SCHEDULE',
            'Anterior': 'Previous',
            'CrearUsuario': 'Create User',
            'GuardarCambios': 'Save Changes',
            'Mensajes': 'Messages',
            'Agentes': 'Agents',
            'WelcomeBack': 'Welcome back,',
            'Home': 'Home',
            'Delete': 'Delete',
            'Add': 'Add',
            'Save': 'Save',
            'Guardar': 'Save',
            'Agregar': 'Add',
            'Vincular': 'Link',
            'Desvincular': 'Unlink',
            'ActualizarDatos': 'Update data',
            'Eliminar': 'Delete',
            'Back': 'Back',
            'Reset': 'Reset',
            'Cancelar': 'Cancel',
            'Details': 'Details',
            'Asignar': 'Assing',
            'Cargando': 'LOADING ...',
            'NoEncontroDatos': 'No data found.',
            'RemoveList': 'Remove List',
            'Cerrarr': 'Close',
            'Cerrar': 'CLOSE',
            'SelectAll': 'Select all',
            'DeselectAll': 'Deselect all',
            'De': ' from ',
            'Confirmar': 'Confirm',
            'Days': 'days',
            'Hours': 'hours',
            'Minutes': 'minutes',
            'Seconds': 'seconds',
            'Ayer': 'Yesterday',
            'Hoy': 'Today ',

            'Editar': 'Edit',
            'Chat ': 'Chat',
            'Chat': 'Chat',
            'Assign': 'ASSIGN',
            'CambiarClave': 'Change Password',

            'Ver': 'See',
            'Go': 'Go',
            'Cambiar Estado': 'Change Status',
            'Atendiendo': 'Attending',
            'Reasignar': 'Reassign',
            'edit': 'Edit',
            'delete': 'Delete',
            'vpn_key': 'Change Password',
            'ConoceMas': 'Know more',
            'Ver conversacion': 'View conversation',
            'Ver contenido': 'View content',
            'Visualizar': 'View',
            'Escanear': 'Scan QR'

        },
        DatosClientes: {
            'AjustesDeLaCuenta': 'Account Settings',
            'NuevaCliente': 'New Client',
            'BasicInfo': 'Basic information',
            'Canales': 'Channels',
            'Galeria': 'Gallery',
            'Contactos': 'Contacts ',
            'Horarios': 'Schedules',
            'Etiquetas': 'Label',
            'Campos': 'Countryside',
            'Atajos': 'Shortcuts',
            'NameEmp': 'Company Name',
            'Rif': 'Rif',
            'Email': 'Email',
            'Phone': 'Phone',
            'Prefijo': 'Prefix',
            'Code': 'Code',
            'Direction': 'Direction',
            'PaymetMethod': 'Paymet Method',
            'MensajeAdicional': 'Additional Message',

            'Datos': 'Data Saved!',
            'DatosGuardados': 'Data Saved please exit and re-enter to refresh the changes',
            'ClienteAgregado': 'Customer Added',
            'Error': 'Error',
            'ArchivoGuardado': 'Saved File',
            'ErrorArchivo': 'File upload error',
            'ErrorGuardado': 'Error al Guardado',
            'Eliminar': 'You want to delete ',
        },
        Canales: {
            'ChannelType': 'Channel Type',
            'MultiOperador': 'Multi-operator',
            'CtrlWhatsApp': 'Control of WhatsApp Multi Agents',
            'CtrlFacebook': 'Control of Facebook Multi Agents',
            'NoDisponible': 'Not available',
            'SignalAgen': 'Signal Multi Agents',
            'TelegAgen': 'Telegram Multi Agents',
            'ScannQR': 'Scanner QR',

            'Telegram': 'Telegram',
            'Facebook': 'Facebook',
            'Whatsapp': 'Whatsapp',
            'Signal': 'Signal',

            'ConfDeMensaj': 'Message Settings',
            'LastnameFirstname': 'Last Name, Firstname',
            'MensajDeBienve': 'Welcome Message',
            'MensajDelContacto': 'Message to Request Name and Surname of the Contact',
            'MensajCorreo': 'Message to Request Contact Email',
            'MensajDatos': 'Message to indicate that the Completed data has Been saved',
            'MensajAgentes': 'Message to Indicate the Assignment of an Agent to the Conversation',
            'MensajConvers': 'Message to Indicate End of Conversation',
            'MensajDisponi': 'Message to indicate that there are no Agents Available',
            'MensajAlerta': 'Alert Message to Contact when Inactivity is Detected in Conversation',
            'MensajFinaliz': ' Message to Indicate that the Conversation will End Due to Contact Inactivity',
            'MensajArchivo': 'Message to Tell the Contact that it is not possible to receive the file',
            'MensajLlamada': 'Message in case of Calls',

            'PermiDelCanal': 'Canal Permits',
            'PermiConvers': 'Allow to Start a Conversation with Unknown Contacts',
            'PermiNotif': 'Allow Send Notifications',
            'PermiDifusiones': 'Allow to send broadcastss (Outbound)',
            'EnviarMensaj': 'Send unavailability message after each message',
            'RecibirConvers': 'Receive group conversations',
            'PermiActuali': 'Allow update of message statuses',
            'PermiArchivos': 'Allow files',
            'BloqueVideos': 'Block videos',
            'MensajVideo': 'Message to indicate that it is not possible to receive the Video files',
            'PermitirAudios': 'Allow Audios',
            'PermitirImágenes': 'Allow Images',

            'Done': 'Done',
            'YouAreNowDone': 'You are now done.',

        },
        ListCanales: {
            'Vincular': 'Link',
            'Desvincular': 'Unlink',
            'ActualizarDatos': 'Update Data',
            'Eliminar': 'Delete',
            'NroDeTeléfono': 'Phone Number',
            'Bateria': 'Drums',
            'VerWhatsapp': 'Whatsapp version',
            'TotalDeMensaje': 'Total Message',
            'Online': 'Online',
            'Status': 'Status',
            'UltimaConexion': 'Last Connection',
        },
        SelectCanales: {
            'Canal': 'Channel',
            'Canales': 'Channels',
            'Todos': '-- EVERYBODY --',
        },
        Campos: {
            'Campo': 'Countryside',
            'Tipo': 'Type',
            'Ubicacion': 'Location',
            'DeseasEliminar': 'You want to delete ',
            'PerderDatosCapturados': ' The data already captured will be lost!',
            'Nuevo_Campo': 'New Field',
            'Editar_Campo': 'Edit Field',

            'Cadenas de Caracteres': 'String',
            'Correo': 'Email',
            "Número": 'Number',
            'Teléfono': 'Telephone',
            'Fecha': 'Date',
            'Nombre': 'Name',
            "Cédula/DNI": "ID / DNI",
            "Tarjeta de Credito": "Credit card",
            'Entrada': 'Input',
            'Texto': 'Text',
            'Texto largo': 'Long text',
            'Numerico': 'Numerico',

            'Contactos': 'Contacts',
            'Chats': 'Chats',
            'Citas': 'Quotes',
            'Cierre': 'Closure',

            'Requerido': 'It is required.',
            'ON': 'ON',
            'OFF': 'OFF',

            'ErrorCampo01:': 'Error Saving Field01: ',
            'ErrorCampo02:': 'Error Saving Field02: ',
            'Agregado': 'Added Field',
            'ErrorAgreganCampo01': 'Error Adding Field01',
            'ErrorAgreganCampo02': 'Error Adding Field02',
            'Otro': 'Other',

        },
        Atajos: {
            'Abreviado': 'Abbreviated',
            'texto': 'Text',
            'Canales': 'Channels',
            'Ubicacion': 'Location',
            'Seleccione': 'Select...',
            'Duplicado': 'Duplicate item, you must use another shortcut for short',
            'DeseasEliminar': 'You want to delete ',
            'Chat': 'Chat',
            'CierreDeCaso': 'Case Closure',
            'NuevoAtajo': 'New Shortcut',
            'EditarAtajo': 'Edit Shortcut',
            'AtajoCreado': 'Created Shortcut',
            'GuardarCambios': 'The changes were saved, but we could not update the system, exit and enter again',
            'AtajoEditado': 'Edited Shortcut',
            'ErrorEditando': 'ERROR EDITING:',


        },
        Etiquetas: {
            'Etiquetas': 'Labels',
            'EditLabel': 'Edit Label',
            'AddLabel': 'Add Label',
            'AddNew': 'ADD NEW LABEL',

            'RemoveDueDate': 'Remove Due Date',
            'AddChecklist': 'Add Checklist',
            'Unsubscribe': 'Unsubscribe',
            'Subscribe': 'Subscribe',
            'RemoveCard': 'Remove Card',
            'Title': 'Title',
            'DueDate': 'Due Date',
            'Description': 'Description',
            'Members': 'Members',
            'Attachments': 'Attachments',
            'MakeCover': 'Make Cover',
            'RemoveCover': 'Remove Cover',
            'RemoveAttachment': 'Remove Attachment',
            'Link': 'LINK',
            'AddAttachment': 'Add an attachment',
            'RemoveChecklist': 'Remove Checklist',
            'Comments': 'Comments',
            'Comment': 'Comment',
            'Activity': 'Activity',

            'AddCard': 'Add a card',

            'EtiquetaGuardada': 'Saved Label',
            'ErrorEditando': 'Error Editing:',
            'EtiquetaAgregada': 'Added Label',
            'GuardarCambios': 'The changes were saved, but we could not update the system, exit and enter again',
            'ErrorGuardando': 'Error Saving',
            'DeseasEliminar': 'You want to delete ',

            'NuevaEtiqueta': 'New Label',
            'EditarEtiqueta': 'Edit Label',
            'EliminarTarjeta': 'Are you sure you want to delete the card?',
            'EliminarLista': 'Are you sure you want to delete the list and it\'s all cards?'
        },
        Horarios: {
            'Horarios': 'Hours',
            'Excepciones': 'Exceptions',
            'DescripJorna': 'Day Description',
            'DiaInicio': 'Start Day',
            'DiaFin': 'End Day',
            'HoraInicio': 'Start Time',
            'HoraFin': 'End Time',
            'Descripcion': 'Descripction',
            'SeleccioneLaFecha': 'Select Date',
            'SeleccioneDia': 'select a day',
            'IngreseDescripcion ': 'Enter a description of the exception',

            'HorarioCreado': 'Schedule Created',
            'GuardarCambios': 'The changes were saved in the Database, but we could not update the system, exit and enter again',
            'ErrorHorario': 'ERROR CREATING SCHEDULE:',
            'HorarioActualizado': 'Updated Hours',
            'ErrorActualizando': 'ERROR UPDATING SCHEDULE:',
            'Error': 'Error',

            'DeseasEliminar': 'You want to delete ',
            'HorarioEliminado': 'Deleted Schedule',
            'HorariosElimidado': 'Schedule Deleted in database but cannot be deleted in memory please exit and re-enter ERROR:',

            "Lunes": "Monday",
            "Martes": "Tuesday",
            "Miercoles": "Wednesday",
            "Jueves": "Thursday",
            "Viernes": "Friday",
            "Sábado": "Saturday",
            "Domingo": "Sunday",

            'Enero': 'January',
            'Febrero': 'February',
            'Marzo': 'March',
            'Abril': 'April',
            'Mayo': 'May',
            'Junio': 'June',
            'Julio': 'July',
            'Agosto': 'August ',
            'Septiembre': 'September',
            'Octubre': 'October',
            'Noviembre': 'November',
            'Diciembre': 'December',

            'HoraDeInicio': 'The start time is equal to the end time, enter a correct time.',
            'HoraFinal': 'The start time is greater than the end time, enter a correct time.',



        },
        Agent: {
            'DatosPersonales': 'Personal Information',
            'Nombre': 'Name',
            'Apellido': 'Firstname',
            'Email': 'Email',
            'Contrasena': 'Password',
            'Telefono': 'Phone',
            'Direccion': 'Direction',
            'HorariosPermisos': 'Schedules / Permissions',
            'Canales': 'Channels',
            'Horarios': 'Schedules',
            'TipoDeUsuario': 'Type of user',
            'RolDelUsuario': 'User Role',
            'AnadirNuevoRol': 'Add new role',
            'Agentes': 'Agents',
            'Ajustes': 'Settings',
            'Agente': 'Agent',
            'Clientes': 'Clients',
            'Administrador': 'Administrator',
            'EmailDuplicado': 'Duplicate email, you must use another email',
            'Master': 'Master',

            'CambioDeClave': 'Key change',
            'ElPassword': 'The Current Password is incorrect',
            'PasswordActual': 'Password Actual',
            'NuevoPassword': 'New Password',
            'PasswordRequired': 'Password is required',
            'ConfirmarPassword': 'Confirmar Password',
            'PasswordCoinciden': 'Password does not match',
            'data.Nombre': 'Agent',

            'AsignacionHorario': 'Assignment of agent (s) schedule',

            'EmailExistente': 'Email already exists, please add another',
            'UserAgregado': 'User Agregado',
            'UserEditado': 'User Editado',

            'CambioClave': 'If the key exchange has been successfully performed',
            'ErrorClave': 'ERROR CHANGING THE PASSWORD ',
            'Eliminar': 'You want to delete ',

            'DeseasColocar': 'You want to place ',
            'EstadoActualizado': 'Agent Status Updated',
            'Error': 'Error',
            'Seleccione': 'Select...',
            'SeleccioneRol': 'Select a role ...',
            'SeccionRequerida': 'This section is required.',
            'TransferirConversaciones': 'Can You Transfer Conversations?',
            'Todos': 'All',
            'Si': 'Yes',

            'HorarioAgregado': 'Schedule added to the agent (s)',
            'SeleccioneAgente': 'Please select an agent who does not have the schedule',

            'BuscandoMensajes': 'Searching Messages',
            'SinMensajes': ' you are without messages',
            'ChatAsignado': 'Assigned Chat',

            'SinMensajess': 'No Messages',
            'EnviarMensajes': 'Send Messages',
            'MostrarAgentes': 'Show Agents'

        },
        tRoles: {
            'Rol': 'Role',
            'domain': 'Domain',
            'Descripcion': 'Description',
            'Permisos': 'Permissions',
            'Usuarios': 'Users',
            'AgregarNuevo': 'Agregar Nuevor',

        },
        ContactoForm: {
            'Name': 'Name',
            'Lastname': 'Lastname',
            'CodeID': 'Code/ID',
            'PhoneNumber': 'Phone Number',
            'phone': 'Phone',
            'Email': 'Email',
            'Company': 'Company',
            'Birthday': 'Birthday',
            'Address': 'Address',
            'Notes': 'Notes',
            'Contacto': 'Contact',
            'Fecha': 'Date',
            'Telefono': 'Phone',
            'Code': 'Code',
            'Extra': 'Extra',
            'Categoria': 'Category',
            'Informacion': 'Information',
            'CamposAdic': 'Additional field.',
            'JobTitle': 'Job title',
            'Remove': 'Remove',

            'ContactInfo2': 'Contact information',
            'Mood': 'Mood',

            'EliminarContacto': 'Are you sure you want to delete all selected contacts?',
            'Eliminar': 'You want to delete ',
            'ListaNegra': 'You want to send to Blacklist ha',
            'ContactoAgregado': 'Added Contact',
            'ErrorContacto': 'Error creating Contact',

            'Nuevo Contacto': 'New Contact',
            'Editar Contacto': 'Edit Contact',
            'NuevoContacto': 'New Contact',
            'EditarContacto': 'Edit Contact',
            'ContactoEditado': 'Contact Edited',
            'ContactoEliminado': 'Contact Deleted',
            'NoEliminarContacto': "Can't delete contact",

            'EditarImagen': 'Edit image',
            'GuardadoExito': 'Saved successfully'

        },
        Chat: {
            'Chat': 'Chat',
            'Select': 'Select a contact to start a chat!',
            'ContactInfo': 'Contact Info',
            'YourBrowser': 'Your browser does not support the video tag.',

            'Mood': 'Mood',
            'Online': 'Online',
            'Away': 'Away',
            'DoNotDisturb': 'Do not disturb',
            'Offline': 'Offline',
            'Profile': 'Profile',
            'Logout': 'Logout',
            'Chats': 'Chats',
            'SearchChat': 'Search or start new chat',
            'Contacts': 'Contacts',
            'NoResults': 'No results..',

            'InfoContacto': ' Contact Information',
            'CerrarCaso': 'Close Case',
            'ConsultarSaldo': 'Check balance',

            'CierreDelCaso': 'Case Closure',
            'MotivoDelCaso': 'Reason for the Case',
            'ConsultaDeSaldo': 'Balance Consultation',
            'Reclamo': 'Claim',
            'ReporteDePago': 'Payment Report',
            'Otro': 'Other',
            'Resumen': 'Summary',
            'ClienteProspecto': 'Prospective client',
            'PromocionesEspeciales': 'Special sales',
            'ReporteProblemaAdministrativo': 'Administrative Problem Report',
            'ReporteServicioTécnico': 'Technical Service Report',
            'SolicitaInformacionDeServicio': 'Request Service Information',


            'Consulta': 'Consultation',
            'Respuesta': 'Answer',
            'EnviarMensaje': 'Send Message',

            'Cargar': 'Load history...',
            'Buscar': 'Search...',
            'AudioElement': 'Your browser does not support the audio element.',
            'Type': 'Type your message',
            'SeleccionarEtiqueta': 'Select Label',
            'Emoji': 'Selected emoji',

            'MensajesAtender': 'Messages to Attend:',
            'MensajesCargados': 'Uploaded Messages 7 /',
            'Servidor': 'Server 7',

            'EnviarEncuestaRapida': 'Send Quick Survey',
            'Titulo': 'Title',
            'Descripcion': 'Description',
            'MensajeAdic': 'Additional Message',
            'VistaPrevia': 'Preview',

            'Seleccione': 'Select...',
            'CasoExito ': 'Case closed successfully',
            'CambiandoEtiqueta': 'The label has been changed successfully',
            'ErrorEtiqueta': 'Error when changing the label',
            'HistorialDeContacto': 'No history found for this contact',

            'EnAtencion': 'Attention:',
            'ClientesEspera': 'Customers Waiting',

        },
        Galeria: {
            'Name': 'Name',
            'Type': 'Type',
            'Owner': 'Owner',
            'Size': 'Size',
            'Created': 'Created',
            'Info': 'information',
            'Asignar': 'Assign to:',

            'EnviarImagen': 'Send image',
            'Descripcion': 'Description',
            'Galeria': 'Gallery',

            'Ninguna': 'None',
            'Enviar': 'Send',
            'AdjuntarImagen': 'Attach Image',
            'Descargar': 'Download',

            'ImagenDisponible': 'Image Available:',
            'Titulo': 'Title',
            'Informacion': 'Information:',
            'Tamaño': 'Size',
            'Asignado': 'Assigned',
            'Creado': 'Created',
            'Modificado': 'Modified',
            'MensajeAdicional': 'Additional Message',

            'AddList': 'Add a list',
            'NombreDeImagen': 'Image name',

            'NuevaImagen': 'Add: New Image',
            'IngreseImagen': 'Enter the name of the image...',
            'Seleccione': 'Select...',
            'Cambiar': 'Change',
        },
        Configuracion: {
            'Administracion': 'Administration',

            'Settings': 'Settings',
            'BoardColor': 'Board Color',
            'CardCoverImages': 'Card Cover Images',
            'Subscribe': 'Subscribe',
            'CopyBoard': 'Copy Board',
            'DeleteBoard': 'Delete Board',
            'Background Color': 'Background Color',

            'Boards': 'BOARDS',
            'ProyectosApp': 'Proyectos App',
            'AddNewBoard': 'Add new board',

        },
        Bot: {
            'NuevaRespuestaDelBOT': 'New BOT Response',
        },
        tPreguntas: {

            'question': 'How to create labels?',
            'answer': '<p> Enter Clients >> Select the company >> in the upper bar choose the option of labels >> you will click on the blue icon (+) >> it will open a window called New label as shown in the image you will enter desired name and channel, then proceed to ADD. \ n </p> <img src="/assets/img/examples/Etiqueta.PNG" alt="">',


            'question0': "How do I add images to the gallery?",
            'answer0': '<p> You enter Gallery >> In the upper right part you will find a button with the name of ADD, you click and it will display a window as shown in the image, in the Assign field you select the Company, then you click In Add and it will open your computer window and select an image, once selected click to save changes. \ n </p> <img src="/assets/img/examples/Galeria.jpg" alt="">',


            'question1': "How to add an Agent's Schedule?",
            'answer1': "Enter Clients >> Select the company, in the upper bar choose the Hours option, you will click on the icon with the symbol (+) it will open a window as shown in the image, in Hours> > Description of the working day, put a brief description >> In Start day you must select the day of the Week in which the agent started his working day and in Start and end time you must enter the Agent's schedule in military hours example (08:00 am / 20: 00pm).",

            'question2': 'How to Add and Edit Agents?',
            'answer2': '<p> Select Agents >> At the top right you will find a button (ADD) it will show a window where it will appear:</p> \n <p> 1 - Personal data: Name >> Surname >> Email >> Password >> Telephone >> Address. As shown in the first picture. Fill in all the fields with the correct data and press NEXT. </p> <img src="/assets/img/examples/agentes1.jpg" alt="">\n <p> Just fill in the fields as they will appear in the second image. </p> \n <p>2- Schedules / Permissions: Clients >> Channels >> Schedules >> Type of user >> Role of the user. Then proceed to CREATE USER, </p>\n <img src="/assets/img/examples/agentes2.jpg" alt=""> \n<p>to Edit the Agent go to the 3 points (...) that are next to each agent, several options will appear, click on Edit then the same window shown in the image will be displayed, but with the information of the Agent you Edit it, give it NEXT and Save the changes.</p>',

            'question3': 'How to create Shortcuts or quick responses?',
            'answer3': '<p> Enter Clients >> Select the company, in the upper bar choose the Shortcuts option, you will click on the icon (+) it will open a window called New Shortcut as shown in the image, you will enter the Abbreviation, the Text , the Channels and the location. Then proceed to WAIT.\n</p> <img src="/assets/img/examples/Atajos.jpg" alt="">',

            'question4': 'How to change the password of an Agent?',
            'answer4': ' <p> Select Agents >> Next to each Agent you will find 3 points (...) the option to Change Password will appear, enter and it will display a window as shown in the image where you can change the password of the Selected agent.\n </p> <img src="/assets/img/examples/CambioClave.jpg" alt="">',

            'question5': 'How to Add and Edit Contact?',
            'answer5': '<p> 1-To Add: Contacts >> In the upper right you will find a button (ADD) when you click it will display a window called New Contact, as you can see in the image, complete all the fields and give it click the (ADD) button at the end of the fields.\n </p><img src="/assets/img/examples/Contactos1.jpg" alt="">\n<p> 2 - Edit: as shown in the image you go to the 3 points (...) that is next to each contact, the option to Edit will appear, you select it, modify the field and click the edit button. \n</p> <img src="/assets/img/examples/Contactos2.jpg.png" alt="">',

            'question6': 'How to Add and Edit basic customer information?',
            'answer6': '<p>1- To Add: You go to Clients >> In the upper right you will find the add button, click it and it will display a view in which it will ask you for the basic information of the client you want to add. \n</p> <img src = "/assets/img/examples/Cliente1.jpg" alt =""> \n<p> 2 - Edit: Customers >> as shown in the image you go to the 3 points (...) that is located next to each Company, the option to Edit will appear, you select it, modify the field and click the SAVE button.\n</p> <img src ="/assets/img/examples/Cliente2.jpg" alt = "">',

            'question7': 'How to Add and Edit new customer contact?',
            'answer7': ' <p> 1 - To Add: Go to Clients >> Select the Company >> In the upper bar choose the Contacts option, you will click on the icon with the blue (+) symbol, a similar window will open As shown in the image, New Contacts fill in all the fields and at the end you will find an Add or Cancel button, when adding your contact will be saved.\n</p> <img src="/assets/img/examples/CienteContacto1.jpg.png" alt=""> \n<p> 2- Edit: As shown in the second image, under Menu you will find 3 lines that is located next to each contact, the option to Edit will appear, select it, modify the field and click the Save button.\n</p> <img src="/assets/img/examples/ClienteContacto2.jpg.png" alt="">',

            'question8': 'How to Add and Edit the Role of an Agent?',
            'answer8': '<p>1 - To Add: Go to Roles >> At the top right you will find the Add button, click it and it will display a view as can be seen in the image with the name fields, the description, permissions and users. Then he just clicks SAVE.\n</p> <img src="/assets/img/examples/Roles1.jpg " alt=""> \n<p> 2 - Edit: as shown in the image you go to the 3 points (...) that is next to each Agent Role, the option to Edit will appear, you select it, modify the field and click the button to SAVE. \n</p> <img src="/assets/img/examples/Roles2.jpg " alt="">',

            'question9': 'How to use Chat Shortcuts?',
            'answer9': '<p> You enter the chat option, select a conversation and in the bar below as shown in the image you place the symbol (/) and then some shortcuts will appear, select it and proceed to send it. \n</p> <img src="/assets/img/examples/Atajoss.jpg" alt="">',

            'question10': 'How can I send images or files to a client?',
            'answer10': '<p> You enter the Chat option, select a conversation and in the lower right you will find an icon of a clip, select it and it will open a sale as shown in the image, you click the button to attach file you select the document or image and then hit the send button. \n</p> <img src="/assets/img/examples/AdjuntarImagen.jpg" alt="">',

            'question11': 'How to Reassign messages to another Agent?',
            'answer11': '<p>You enter the chat option, select a conversation and in the bar below as shown in the image you place the @, the names of the agents you select and then send will appear.\n</p> <img src="/assets/img/examples/ReasignarMensajes.jpg" alt="">',

            'question12': "How can I verify a customer's information?",
            'answer12': '<p>You enter the chat option, select a conversation at the top right, you will find 3 points, click it and it will display a box as it appears in the first image, select Contact Info and it will open a window as shown In the second image.\n</p> <img src="/assets/img/examples/InfoCliente.jpg " alt=""> <img src="/assets/img/examples/InfoCliente2.jpg" alt="">',

            'question13': 'How to check the Balance of a client?',
            'answer13': '<p> You enter the Chat option, select a conversation in the upper right part you will find 3 points, click it and it will display a box as it appears in the first image, select Check Balance or on the keyboard (Ctrl + y) And it will open a window as shown in the second image in the field of Consultation you put the identification or ID of the client and in the field of Response its balance and proceeds to send the message.\n</p> <img src="/assets/img/examples/InfoCliente.jpg " alt=""> <img src="/assets/img/examples/ConsulaSaldo.jpg" alt="">',

            'question14': "Where can I close a client's case?",
            'answer14': '<p> You enter the Chat option, select a conversation in the upper right part you will find 3 points, click it and it will display a box as it appears in the first image, select Close case or on the keyboard (Ctrl + b) and a window will open as shown in the second image, 2 fields will appear, the Reason for the case and the Summary, fill in both and click on Close case.\n</p> <img src="/assets/img/examples/InfoCliente.jpg " alt=""> <img src="/assets/img/examples/CierreCaso.jpg" alt="">',

            'question15': 'How to access the Quick Survey?',
            'answer15': '<p> You enter the Chat option, select a conversation in the upper right corner, you will find 3 points, click it and it will display a box as it appears in the first image, select Send quick survey and it will open a window as shown In the second image, you have several fields Title, description, additional message then you will find 3 squares where you will place (Ahem: Yes or No) and when filling in the fields, below you have a preview where you can see how your survey will look before sending it.\n</p> <img src="/assets/img/examples/InfoCliente.jpg " alt=""> <img src="/assets/img/examples/EncuestaRapida.jpg" alt="">',

            'question16': 'How to tag conversations?',
            'answer16': '<p> Enter the Chat >> Select a conversation then in the upper right part of the chat you will find the icon of a label as shown in the image click and select the corresponding one.\n</p> <img src="assets/img/examples/EtiquetarConvers.jpg" alt="">',

            'question17': 'How to add and delete images in the Clients gallery? ',
            'answer17': '<p>1 - To Add: Go to Clients >> Select the Company >> In the upper bar choose the gallery option, click on the blue button with the (+) sign that is in the part upper left. A window will open so that you can add the image that is on your computer as shown in the image, you select the image and it will be saved for you.\n</p><img src="assets/img/examples/ImagenGaleria.jpg" alt=""> \n<p> 2- Delete: Click on the image and at the bottom right you will find a red DELETE button. \n</p> <img src="/assets/img/examples/ImagenGaleria1.jpg" alt=""> ',

            /* 'question18': 'Lock functionality?',
            'answer18': "<p>In the upper right part of the username you click as shown in the first image you select Blocking will open a window as you can see in the second image, you have a Reason field where you can select the option to Rest, In meeting or Other. You can also UNLOCK or in Isn't it You? You can change user." ,
 */
            'FrequentlyAskedQuestion': 'Frequently Asked Question',
            'SearchInFAQs': 'Search in FAQs...',

        },
        tNovedades: {
            'HowCanWehelp': 'How can we help?',
            'WelcomeToOurknowledgeBase': 'Welcome To Our knowledge Base',
            'SeeallArticles': 'See all articles',

            'Your Account': 'Customize',
            'Account limits': 'System Appearance',
            'Contenido_Account_limits': '<p><b> Change of appearance of the page. </b></p>\n<p> Our system now has the option to change the appearance of the page with different Themes and Tonalities options, as well as customize the navigation bar, the positioning of the page, the bar toolbar and footer by choosing your own style and color preference.\n</p> <div class = "ImgKnow"><div class = "ImgKnow"><img src="/assets/img/examples/Personalizar.jpg" alt=""> </div>',
            'Chat': 'Chat',
            'Chat1': 'Chat Shortcuts',
            'Content_Chat1': '<p><b>Chat update.</b></p>\n<p> Now with the new version of ThomasV9 in the Chat section we can respond to messages quickly and efficiently with the shortcuts or quick answers that we can visualize by placing the symbol of the slash (/).\n</p><div class = "ImgKnow"><img src="/assets/img/examples/Atajoss.jpg" alt=""> </div>',
            'Chat2': 'Number of messages.',
            'Content_Chat2': '<p><b>Total Conversations </b> </p> \n <p> Another of the new chat improvements is the display of the number of messages that operators and customers are attending that are on hold.\n</p> <p>Attention >> The total number of chats you have in attention is displayed. \n</p> <p> Customers waiting >> Shows the total number of customers waiting to be served.\n</p><div class = "ImgKnow"><img src="/assets/img/examples/Chat.JPG" alt=""> </div>',
            'Chat4': 'Reorder Chat',
            'Content_Chat4': '<p><b> Reorganization of messages.</b> </p> \n <p> Agents can now reorder conversations using "Drag & Drop" (Drag and Drop any chat). \n </p><div class = "ImgKnow"><img src="/assets/img/examples/Reordenar chat.jpg" alt=""> </div>',

            'Chat3': 'Labels',
            'Content_Chat3': '<p><b> Tag a conversation.</b></p>\n<p> With this new chat update you can identify conversations using Failure Report tags, Payments or balance inquiry, to group messages and be able to obtain statistics on the number of conversations or cases, thanks to the grouping of these conversations, efficient management reports are obtained. \n</p><div class = "ImgKnow"> <img src = "/assets/img/examples/EtiquetarConvers.jpg" alt = ""> </div> ',
            'Inicio': 'Home',
            'Idioma': 'Languages',
            'content_Idioma': '<p><b> Language Change </b></p>\n<p> With this new chat update you can identify conversations using Failure Report tags, Payments or balance inquiry, to group messages and be able to obtain statistics on the number of conversations or cases, thanks to the grouping of these conversations, efficient management reports are obtained.\n </p><div class = "ImgKnow"><img src="/assets/img/examples/Idioma.JPG" alt="" ></div> ',
            'Bloqueo': 'Lock',
            'content_Bloqueo': '<p><b>Session lock.</b></p> \n <p>Our system also has a session lock option where you can view a counter that indicates how long you have been disconnected and you can indicate the reason. \n</p><div class = "ImgKnow"> <img src="/assets/img/examples/Bloqueo2.jpg" alt="" > </div> ',
            'Galeria': 'Gallery',
            'GaleriaDeImagen': 'Image Gallery',
            'content_Imagen': '<p> <b> Gallery Section. </b> </p> \ n <p> Our system has a gallery section where the design or marketing department will be able to have a full administrator to The graphic part that the agents are going to use when serving customers in this way makes it easier and more efficient to have all the tools for good service. \ n </p> <p> In this tool we can add, delete, consult images and PDF that will be sent to our clients.\n</p><div class = "ImgKnow"><img src="/assets/img/examples/Galeria1.jpg" alt="" ></div>',

            'Agentess': 'List of agents',
            'Agentes': 'Agents',
            'content_Agentes': '<p><b> List of agents. </b></p>\n<p> With the new Thomas update, this new section was added where you can view a list of all Agents and those that belong to each Company, it also has a menu with a series of options to edit the agent data, change its password or remove it from the list.\n </p><div class = "ImgKnow"><img src="/assets/img/examples/Agentes.jpg" alt="" ></div>',
            'AgregarAgent': 'New agents',
            'content_AgregarAgent': '<p><b>Key Change of Agents.</b></p>\n<p>From the same system you can add the Agents with the corresponding personal data, you can also add the working hours and the permissions or accesses placed by the Administrator or Master.\n </p><div class = "ImgKnow"><img src="/assets/img/examples/agentes2.jpg" alt="" ></div>',
            'CambioClave': 'Change Password',
            'content_CambioClave': "<p><b>Agents password change.</b></p>\n<p> In a simple and fast way you can change the agent's password or password, placing the current password, the new password and the confirmation of the password. \n </p> <div class = 'ImgKnow'> <img src='/assets/img/examples/AgenteClave.jpg' alt=' '> </div> ",
            'Roles': 'Roles',
            'Roles1': 'Roles or permissions',
            'content_Roles': '<p><b>Agent Permissions.</b></p>\n<p> We have a section called Roles, where administrators and masters have the ability to Add, Edit and Delete the Roles, which determine who have permission to enter and view in the system. \n </p> <div class = "ImgKnow"><img src="/assets/img/examples/Roles2.jpg" alt="" ></div>',
            'Roles2': 'Roles options',
            'content_Roles2': '<p><b>Options to add a Role. </b> </p> \n <p> To set the permissions to an agent we have a series of options such as the Name if it is an agent or administrator, a brief description of the Role, the permissions to the different sections that you can view in the system and finally we have a list of users or operators to whom the Role will be assigned. \n </p> <div class = "ImgKnow"><img src="/assets/img/examples/Roles3.jpg" alt="" ></div>',
            'Contacto': 'Contacts',
            'Contacto1': 'Contact list',
            'content_Contacto': '<p><b>Customer Contact. </b></p> \n <p> On the platform we have a Contact option where we can view, consult, add and edit All telephone numbers of our Clients. \n </p> <div class = "ImgKnow"><img src="/assets/img/examples/Contactos1.jpg.png" alt="" ></div>',
            'Chat5': 'Total de Chat',
            'Content_Chat5': '<p><b>Total conversations. </b> </p> \n <p> Another of the new improvements of the chat is the visualization of the number of messages that the operators and the Clients that are on hold. \n </p> <p> Attention: 50 >> The total number of chats you have in attention is displayed. \n </p> <p> Customers waiting >> Shows the total number of customers waiting to be served.\n </p><div class = "ImgKnow"><img src="/assets/img/examples/TotalChat.jpg" alt="" ></div>',
            'Chat6': 'History',
            'Content_Chat6': '<p><b>Search history. </b> </p> \n <p> You can search the history of an existing conversation by typing the phone number and clicking search conversation. \n </p><div class = "ImgKnow"><img src="/assets/img/examples/Historial.jpg" alt="" ></div>',
            'Chat7': 'Chat Multimedia Files',
            'Content_Chat7': '<p><b>Chat update. </b> </p> \n <p> Other new updates that were made to the chat is to be able to send and receive Videos, PDF documents, emoji, stickers and even gif ... So that the interaction with our customers is more pleasant. \n </p><div class = "ImgKnow"><img src="/assets/img/examples/multimedia.JPG" alt="" ></div>',
            'Chat8': 'Chat Polls',
            'Content_Chat8': '<p><b>Quick Surveys. </b> </p> \ n <p> Now you can send surveys with closed responses, it is edited at the top and at the bottom you have the preview of how the customer will receive the survey (you must send a minimum of 2 options and a maximum of 3). \n </p><div class = "ImgKnow"><img src="/assets/img/examples/EncuestaRapida.jpg" alt="" ></div>',
            'Chat9': 'Contact Information',
            'Content_Chat9': '<p><b>Contact Information.</b> </p> \n <p> We can also view the personal information of the contact that is stored in the database, in addition they can be added "Additional fields" (added from the user). These fields will load in the additional fields tab so that they can store extra information. \n </p><div class = "ImgKnow"><img src="/assets/img/examples/infoContacto.JPG" alt="" ></div>',
            'Tecnicos': 'Technicians',
            'Tecnicoss': 'Registros de Técnicos',
            'content_Tecnicos': '<p> <b>Technician records </b></p> \n <p> Currently we have the technicians section, users with access to this section can add new technicians corresponding to their companies or companies, it was created mainly for to be able to administer each one of the technicians, contractors and vehicles by the companies to which they have access, allowing to add, consult, and eliminate each one of those records. \n</p><div class = "ImgKnow"><img src="/assets/img/examples/Tecnicos.JPG" alt="" ></div>',
            'Reportess': 'Advanced search',
            'Reportes': 'Reports',
            'content_Reportes': "<p><b> Advanced search reports </b></p> \n <p> Users with access to this section will be able to perform specific searches on the history of conversations with the company's clients, through keywords, Client's name, telephone number, ID, the channel through which the client was served, the type of message, whether it be only text, images, audios, videos, PDF, etc., they may place labels and a date range to perform the search of all that information. \n </p> <div class = 'ImgKnow'><img src='/assets/img/examples/Reportes.JPG' alt='' ></div>",
            'ResultadoBusqueda': 'Search result',
            'content_ResultadoBusqueda': "<p> <b> Search result </b> </p> \n <p> They will have different columns depending on the search performed, in this case, the Customer's telephone number is displayed in Mobile by which the message was received, the type of content, the content of the message, the date, they will also have a filter to add more columns and they will be able to export the final result to Excel.\n </p> <div class = 'ImgKnow'><img src='/assets/img/examples/Reporte1.JPG' alt='' ></div>",
        },
        tCustomize: {
            'ThemeOptions': 'Theme Options',
            'ColorThemes': 'Color Themes',
            'DefaultLight': 'Default Light',
            'YellowLight': 'Yellow Light',
            'BlueGrayDark': 'Blue-Gray Dark',
            'PinkDark': 'Pink Dark',

            'LayoutStyles': 'Layout Styles',
            'VerticalLayout1': 'Vertical Layout #1',
            'VerticalLayout2': 'Vertical Layout #2',
            'VerticalLayout3': 'Vertical Layout #3',
            'HorizontalLayout1': 'Horizontal Layout #1',

            'LayoutWidth': 'Layout Width',
            'FullWidth': 'Fullwidth',
            'Boxed': 'Boxed',

            'Navbar': 'Navbar',
            'Hide': 'Hide',
            'Folded': 'Folded',
            'Position': 'Position:',
            'Left': 'Left',
            'Right': 'Right',
            'Variant': 'Variant:',
            'Style1': 'Style1',
            'Style2': 'Style2',
            'PrimaryBackground': 'Primary Background:',
            'SecondaryBackground': 'Secondary Background:',

            'Toolbar': 'Toolbar',
            'Above': 'Above',
            'BelowStatic': 'Below Static',
            'BelowFixed': 'Below Fixed',
            'UseCustomBackgroundColor': 'Use custom background color',
            'BackgroundColor': 'Background color:',

            'Footer': 'Footer',
            'Color': 'Colour:',

            'SidePanel': 'Side Panel',

            'CustomScrollbars': 'Custom scrollbars',
            'EnableCustomScrollbars': 'Enable custom scrollbars',
        },
        Perfil: {
            'MyProfile': 'My Profile',
            'Bloqueo': 'blocking',
            'Logout': 'Logout',

            'Follow': 'Follow',
            'SendMessage': 'Send Message',

        },
        Today: {
            'Today': 'Today',
            'Events': 'Events',
            'Notes': 'Notes',
            'QuickSettings': 'Quick Settings',
            'Notifications': 'Notifications',
            'CloudSync': 'Cloud Sync',
            'RetroThrusters': 'Retro Thrusters',
            'Version / Servidor': 'Version / Server',
            'note.detail': 'Cutoff Date:',

        },

        Login: {
            'Welcome': 'Welcome to the Thomas!',
            'Lorem': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ullamcorper nisl erat,vel convallis elit fermentum pellentesque. Sed mollis velit facilisis facilisis viverra.',
            'LoginTo': 'LOGIN TO YOUR ACCOUNT',
            'Email': 'Email',
            'EmailIsRequired': 'Email is required',
            'CorreoNoValido': 'The email is not valid',
            'Password': 'Password',
            'PasswordNoCorrecto': 'The password is not correct.',
            'NoReconoceClave': 'User or password is not recognized',
            'ExcedioMaximo': 'You have exceeded the maximum number of attempts, please try again later.',
            'SuCuentaBloqueada': 'Your account has been locked for security, contact your supervisor for more information.',
            'RememberMe': 'Remember Me',
            'ForgotPassword': 'Forgot Password?',
            'Login': 'Login',
            'LogInWithGoogle': 'Log in with Google',
            'LogInWithFacebook': 'Log in with Facebook',
            'HaveAnAccount': "Don't have an account?",
            'CreateAccount': 'Create an account',
            'OR': 'OR',
            'Accept': 'Accept',

            'Create': 'CREATE AN ACCOUNT',
            'NombreEmpresa': 'Company name',
            'NameEmpresaRequired': 'Name Empresa is required',
            'NameRequired': 'Name is required',
            'UbicacionEmpresa': 'Location of the company',
            'locationRequired': 'location is required',
            'TelefonoContacto': 'Telephone contact',
            'PhoneRequired': 'Phone is required',
            'Name': 'Name',
            'PleaseAddress': ' Please enter a valid email address',
            'PasswordConfirm': 'Password (Confirm)',
            'PasswordRequired': 'Password is required',
            'PasswordConfirmation': 'Password confirmation is required',
            'PasswordsMatch': 'Passwords must match',
            'Creread': 'I read and accept',
            'termsConditions': 'Terms and conditions',
            'AlreadyAccount': 'Already have an account?',

            'RecoverPassword': 'RECOVER YOUR PASSWORD',
            'Send': 'SEND RESET LINK',
            'Gologin': 'Go back to login',
            'ResetPassword': 'RESET YOUR PASSWORD',
            'ResetMyPassword': 'RESET MY PASSWORD',

            'YourSession': 'YOUR SESSION IS LOCKED',
            'DueInactivity': 'Due to inactivity, your session is locked. Enter your password to continue.',
            'Username': 'Username',
            'Unlock': 'UNLOCK',
            'AreYouNot': "Aren't you?",
            'InvalidPassword': 'Invalid Password',
            'Motivo': 'Reason',
            'lock': 'lock',
            'Descanso': "Rest",
            'EnReunion': "In a meeting",
            'Otro': "Other",

            'ConfirmEmail': 'Confirm your email address!',
            'ConfirmationEmail': 'A confirmation e-mail has been sent to',
            'ConfirmMyEmail': 'Check your inbox and click on the "Confirm my email" link to confirm your email address.',
            'GoBackToLoginPage': 'Go back to login page',

            'Post': 'POST',
            'Posted': 'posted on your timeline',
            'Shared': 'shared something with you',
            'SharedVideo': 'shared a video with you',
            'SharedArticle': 'shared an article with you',
            'Like': 'Like',
            'Share': 'Share',
            'Reply': 'Reply',
            'PostComment': 'Post Comment',
            'LatestActivity': 'Latest Activity',
            'SeeAll': 'See All',

            'ExtenderSesion': '¿Do you want to extend your session?',
            'SessionExtend': 'Extend Session',
            'CerrarSesion': 'Sign off',
            'Cuenta': 'YOUR ACCOUNT HAS ALREADY BEEN VERIFIED SUCCESSFUL'

        },
        Event: {
            'Title': 'Title',
            'PrimaryColor': 'Primary color',
            'SecondaryColor': 'Secondary color',
            'AllDay': 'All Day',
            'StartDate': 'Start date',
            'StartTime': 'Start time',
            'EndDate': 'End date',
            'EndTime': 'End time',
            'Location': 'Location',
            'Notes': 'Notes',

        },
        Calendar: {
            'Campañas': 'Campaigns',
            'Eliminar': 'Are you sure you want to delete?',
            'ClickAdd': 'Click to add / remove shortcut',
            'Search': 'Search for an app or a page',
            'Shortcuts': 'No shortcuts yet!'
        },
        Error: {
            'Sorry': 'Sorry but we could not find the page you are looking for',
            'GoBackToDashboard': 'Go back to dashboard',

            'WellInternet': 'Well, you broke the internet!',
            'Justkidding': 'Just kidding, looks like we have an internal issue, please try again in couple minutes',
            'ReportThisProblem': 'Report this problem',

            'Closed': 'Closed for scheduled maintenance!',
            'SorryFor': "We're sorry for the inconvenience.",
            'Please': 'Please check back later.',

        },
        Factura: {
            'Invoice': 'INVOICE',
            'DueDate': 'DUE DATE',
            'Service': 'SERVICE',
            'Unit': 'UNIT',
            'UnitPrice': 'UNIT PRICE',
            'Quantity': 'QUANTITY',
            'Total': 'TOTAL',
            'Subtotal': 'SUBTOTAL',
            'Tax': 'TAX',
            'Discount': 'DISCOUNT',
            'Please': 'Please pay within 15 days. Thank you for your business.',
            'InCondimentum ': 'In condimentum malesuada efficitur. Mauris volutpat placerat auctor. Ut ac congue dolor. Quisquescelerisque lacus sed feugiat fermentum. Cras aliquet facilisis pellentesque. Nunc hendreritquam at leo commodo, a suscipit tellus dapibus. Etiam at felis volutpat est mollis lacinia.Mauris placerat sem sit amet velit mollis, in porttitor ex finibus. Proin eu nibh id liberotincidunt lacinia et eget eros.',

            'Phone': 'Phone:',
            'Email': 'Email:',
            'Web': 'Web:',
            'Cliente': 'CLIENT',
            'InvoiceDate': 'INVOICE DATE',
            'TotalDue': 'TOTAL DUE',

            'SimplePricing': 'Simple Pricing!',
            'Advanced': 'The most advanced customer support tools with a simple and affordable pricing. And you can always try for 30 days, free!',
            'Starter': 'Starter',
            'ForSmall': 'For small teams',
            'Monthly': 'Monthly per user',
            'Unlimited': 'Unlimited projects',
            'UnlimitedPages': 'Unlimited pages',
            'UnlimitedDiskSpace': 'Unlimited disk space',
            'FreeSupport': '24 / 7 Free support',
            'GetStarted': 'GET STARTED FREE',
            'DayFree': '7 day free trial to start',
            'Pro': 'Pro',
            'ForLargerTeams': 'For larger teams',
            'AdvancedReporting': 'Advanced reporting',
            'Customizable': 'Customizable interface',
            'Integration': 'CRM Integration',
            'DayFreee': '30 day free trial to start',
            'Enterprise': 'Enterprise',
            'ForBigTeams': 'For big teams',
            'ForFull': 'For full feature list, call us',
            'DayyFree': '90 day free trial to start',
            'Frequently': 'Frequently Asked Questions',
            'HowDoesFree': 'How does free trial work?',
            'Lorem': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur a diam nec augue tincidunta ccumsan. In dignissim laoreet ipsum eu interdum.',
            'CanCancel': 'Can I cancel any time?',
            'Aliquam': 'Aliquam erat volutpat. Etiam luctus massa ex, at tempus tellus blandit quis. Sed quis neque tellus.Donec maximus ipsum in malesuada hendrerit.',
            'WhatHappens': 'What happens after my trial ended?',
            'CanDiscount': 'Can I have a discount?',


        },
        Citas: {
            'Citas': 'Quotes',
            'Eliminar': 'Are you sure you want to delete?',
        },
        Inicio: {
            'FacturasMasivas': 'Mass Invoices',
            'AnularFacturas': 'Cancel Invoice',
            'ConsultarFactura': 'Consult Invoice',
            'Nro Factura': 'Invoice No.',
            'TipoFactura':'Type of Invoice',
            'Estadistica': 'Statistics',
            'Cola': 'Tail',
            'Clientes': 'Clients',
            'Cerrados': 'Closed',
            'Mensajes': 'Messages',
            'Nuevos': 'New',
            'Casos': 'Cases',
            'Atendidos': 'Attended:',
            'Ayerr': 'Yesterday:',

            'Total': 'Total in queue:',
            'ThomasBOT': ' Thomas BOT:',
            'Reasignados': 'Reassigned:',
            'CasosCerrados': 'Closed Cases',

            'EnviosMasivos': 'Mass shipments',
            'SeleccionaCanal': 'Select a channel',
            'SeleccionarArchivo': 'Select File',
            'DetenerProceso': 'Stop process',
            'EliminarArchivo': 'Delete File',
            'EnviarMensajes': 'Send',
            'CancelarMasivo': 'Cancel',
            'ImagenMasivo': 'Add Image',
            'ImagenMasivoVer': 'View image',
        },
        Tecnicos: {
            'Contratistas': 'Contractors',
            'SeleccionaContratistas': 'Select contractors',
            'Nombre': 'Name',
            'Rif': 'Rif',
            'Telefono': 'Telephone',
            'PersonaDecontacto': 'Contact person',
            'Email': 'Email',
            'Direccion': 'Direction ',
            'GuardarInformacion': 'Save Information',
            'AgregarTécnico': 'Add new technician',
            'AgregarContratista': '--- Add Contractor ---',
            'Apellido': 'Last name',
            'Cédula': 'ID',
            'Telefono1': 'Phone  1',
            'Telefono2': 'Phone  2',
            'Birthday': 'Birthday',
            'Vehiculos': 'Vehicles',
            'AgregarVehiculo': '--- Add Vehicle ---',
            'Contacto': 'Contact',
            'Marca': 'Brand',
            'Modelo': 'Model',
            'Color': 'Colour',
            'Placa': 'Plate',
            'Tipo': 'Type',
            'TipoDeVehiculo': 'Vehicle Type',
            'PolizaDeSeguro': 'Insurance Policy',

            'DeseasEliminar': 'You want to eliminate ',
            'InformacionAgregada': 'Information added successfully',
            'InformacionEditada': 'Information edited successfully',

            'SeleccEmpresa': 'You must select a company',
            'Cerrar': 'Close',

            'ModificarContratista': 'Modify Contractor',
            'EliminarContratista': 'Delete Contractor',
            'ModificarVehiculo': 'Modify Vehicle',
            'EliminarVehiculo': 'Delete Vehicle',
            'Archivo': 'The file must not be more than 4MB',
            'ErrorArchivo': 'File upload error:',
            'ErrorGuardarlo': 'Save Error',
            'ExpiraciónLicencia': 'License expiration'
        },
        Home: {
            'Beneficios': 'Benefits',
            'Soluciones': 'Solutions',
            'Nosotros': 'We',
            'Contacto': 'Contact',
            'IniciarSesion': 'Login',
            'SoftwareChat': 'MULTICHANNEL CHAT SOFTWARE',
            'PotenciaNegocio': 'Power your business by offering quick solutions via WhatsApp.',
            'OptimiceComunicacion': 'Optimize your communication, manage your data and have your own platform to improve the interaction of your customers through human agents and',
            'SolicitarDemo': 'Request Demo',
            'BeneficiosThomas': 'Benefits of using Thomas to attend all your chats',
            'MultiCanal': 'MultiChannel',
            'multiplesAgentes': 'You can have multiple agents attending conversations of',
            'Metricas': 'Real-time metrics',
            'flujoDeConversaciones': 'Know the flow of conversations, average time of these and the',
            'CalidadRespuesta': 'response quality',
            'Agentes': 'by your agents.',
            'Thomas': 'Now Thomas moves with you',
            'AppThomas': 'With our Thomas App, your team will have the ability to move without neglecting customer service.',
            'AutomatizaThomas': 'Automate with Thomas',
            'BrindaAtencion': 'Provide 24/7 attention with our',
            'ChatDiseñados': 'custom designed chat',
            'Necesidades': 'of your needs.',
            'CasosDeUso': 'Use Cases',
            'Ventas': 'Sales',
            'AumentaConversiones': 'Increase conversions and drive sales through real-time conversations.',
            'AtencionCliente': 'Customer service and support',
            'BrindaSoporte': 'Provide timely support to your customers through their preferred channels.',
            'LogisticaDitribucion': 'Logistics and distribution of orders',
            'GestionaPedidos': 'Manage order taking with your agents or with our custom designed chat.',
            'HechoPor': 'Made By:',


            'Plantillas': 'Templates for WhatsApp',

            'PlantilaConte': "When it comes to selling, speed is everything. Accelerate your response speed with our built-in WhatsApp templates. You can automatically send messages based on triggers, such as reaching a certain stage in the process. The best part? You can customize the templates with information from the prospect's profile, so that no message appears robotic.",

            'BotNegocio': "Building a chatbot for WhatsApp doesn't have to be difficult. Our no-code BOT creator makes it easy to create a bot that can collect information from prospects, fill out their profiles, initiate automatic actions, and much more.",


            'Asesores': 'Follow the performance of your advisers',
            'NuevoCliente': "You still don't know if someone responded to that new customer?¿Aún no sabe si alguien respondió a ese nuevo cliente?",
            'EnThomas': "At Thomas, managers don't need to question: they can easily create and view tasks, open any customer profile to see the full story, and measure performance with our reporting tools.",
            'Comunicacion': 'It is a communication hub for all your business conversations. Each prospect gets their own full-featured profile, where you can communicate with them using their favorite channels: phone call, SMS, email, or messaging like WhatsApp.',
            'Equipo': "When it comes to using WhatsApp as a team, things can get confusing quickly: separate phone numbers, different devices ... it's a headache. That's why Thomas allows all team members to share a single WhatsApp account.",
            'Equipo2': "And don't worry about digging into conversations that aren't yours - in Thomas, you are only notified of prospects that are assigned to you"
        },
        ValidationsForm: {
            "USERNAME_REQUIRED": "Username is required",
            "USERNAME_MIN_LENGHT": "Username must be at least 4 characters.",
            "PASSWORD_REQUIRED": "Password is required.",
            "REQUIRED": "This field is required.",
            "LOGIN_INVALID": "Incorrect username and / or password",
            "MIN_CHARACTER_REQUIRED": "Data length less than the minimum required.",
            "MAX_CHARACTER_ALLOWED": "Data length more than the maximum allowed.",
            "CHARACTER_NOT_ALLOWED": "Invalid Data.",

            "LOADING": "Cargando, por favor espere.",
            "SIGN_IN": "Sign In"
        }
    }
};
