import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import localEs from '@angular/common/locales/es-VE';
import { registerLocaleData, DatePipe } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { JwtModule } from '@auth0/angular-jwt';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { AppStoreModule } from 'app/store/store.module';
import { LayoutModule } from 'app/layout/layout.module';

import { ApolloModule, APOLLO_OPTIONS } from "apollo-angular";
import { HttpLinkModule, HttpLink } from "apollo-angular-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { FakeDbService } from 'app/fake-db/fake-db.service';

import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire'
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { MessagingService } from './services/messaging.service';
import { AsyncPipe } from '../../node_modules/@angular/common';

import { environment } from 'environments/environment';
import { PickerModule } from '@ctrl/ngx-emoji-mart';

import { Apollo } from 'apollo-angular';
import { split } from 'apollo-link';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { MomentModule } from 'angular2-moment';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SelectAutocompleteModule } from 'mat-select-autocomplete';
import { RouterModule, Routes } from '@angular/router';
import { ReportesFakeDb } from './fake-db/Reportes';
import { ProjectV2Module } from './main/apps/dashboards/project-v2/project-v2.module';
import { HomeGuard } from './services/guards/home.guard';
import { LoginGuard } from './services/guards/login.guard';
import { MaterialModule } from './material.module';
import { SocketService } from 'app/services/global/socket.service';
registerLocaleData(localEs, 'es');

const appRoutes: Routes = [
  { path: 'apps', canActivate: [HomeGuard], loadChildren: './main/apps/apps.module#AppsModule' },
  { path: 'pages',/*canActivate: [HomeGuard],*/ loadChildren: './main/pages/pages.module#PagesModule' },
  { path: 'reportes', canActivate: [HomeGuard], loadChildren: './main/ui/ui.module#UIModule' },
  { path: 'billing', canActivate: [HomeGuard], loadChildren: './main/apps/billing/billing-section.module#BillingSectionModule' },
  { path: '**', canActivate: [LoginGuard], redirectTo: '/pages/home' }
];

@NgModule({
  declarations: [
    AppComponent,
  ],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpLink) => { return { cache: new InMemoryCache({ addTypename: true }), link: httpLink.create({ uri: environment.uriGraphql }) } },
      deps: [HttpLink],
    },
    { provide: LOCALE_ID, useValue: 'es' },
    MessagingService,
    AsyncPipe,
    DatePipe,
    ReportesFakeDb,
    SocketService
  ],
  imports: [
    InMemoryWebApiModule.forRoot(FakeDbService, {
      delay: 0,
      passThruUnknownUrl: true
    }),
    //RouterModule.forRoot(routes),
    RouterModule.forRoot(appRoutes, { anchorScrolling: 'enabled' }),
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    PickerModule,
    ApolloModule,
    HttpLinkModule,
    HttpClientModule,
    // App modules
    LayoutModule,
    AppStoreModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFireMessagingModule,
    NgIdleKeepaliveModule.forRoot(),
    MomentModule,
    NgxSpinnerModule,
    SelectAutocompleteModule,
    ProjectV2Module,
    JwtModule,
    TranslateModule.forRoot(),
    // Fuse modules
    FuseModule.forRoot(fuseConfig),
    FuseProgressBarModule,
    FuseSharedModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
  constructor(apollo: Apollo, httpLink: HttpLink) { }
}

/*     // Create an http link:
        const http = httpLink.create({
          uri: 'http://localhost:4000/'
        });
        // Create a WebSocket link:
        const ws = new WebSocketLink({
          uri: ` ws://localhost:4000/subscriptions`,
          options: {
            reconnect: true
          }
        });
        // using the ability to split links, you can send data to each link
        // depending on what kind of operation is being sent
        const link = split(
          // split based on operation type
          ({ query }) => {
            const { kind, operation } = getMainDefinition(query);
            return kind === 'OperationDefinition' && operation === 'subscription';
          },
          ws,
          http,
        ); */

/*     apollo.create({
      link,
      // ... options
    }); */