import { Component, OnInit, ViewEncapsulation, OnDestroy} from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { GetTableService } from '../../e-commerce/getTable.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {GetTableV2Service} from 'app/services/global/getTableV2.service';
import {isEmpty} from 'lodash';
import { ProjectV2Service } from '../project-v2.service';
import {takeUntil} from 'rxjs/operators';
import {SubscriptionGraphqlService} from 'app/services/global/subscription-graphql.service';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-project-v2',
  templateUrl: './project-v2.component.html',
  styleUrls: ['./project-v2.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class ProjectV2Component implements OnInit, OnDestroy {

  _unsubscribeAll: Subject<any>;

  //colors=["#03a9f4","#03a9f4","#03a9f4","#03a9f4","#556b74","#556b74","#556b74","#556b74","#ff9800","#ff9800","#ff9800","#ff9800"]
  colors=["#03a9f4","#ff9800","green"]
  widgets: widget[] = []
  widgetSelected: widget[] = []
  // eso me deja sin palabras Jhonattan
  projects:any = [
  ]
  agentes = []
  selectedProject:any = {
  }
  Pause= false
  timer:any
  totalProject=0
  IndexProject=0
  noShow=false

  constructor(private _GetTableService: GetTableService, private getTableServiceV2: GetTableV2Service,
    private projectService: ProjectV2Service,
    private _SubscriptionGraphqlService:SubscriptionGraphqlService

  ) {
      console.log(this.projectService.projects)
      
      if (this.projectService.YaTengoTodo && this.projectService.projects != undefined) {
        //console.log("if")
        this.getWidgets2(this.projectService.projects)
      }else{
      this.projectService.CargaProjects.subscribe((data)=>{
        //console.log("estoy esperando")
        //console.log("Data desde el service: ",data);
        this.getWidgets2(this.projectService.projects)

      })
      }
  }

  ngOnInit() {
/*     this.timerStart() */
  }
  
  ngOnDestroy(): void
  {
    this.timerStop()
    //this._unsubscribeAll.next();
    //this._unsubscribeAll.complete();
  }


  selectClientProject(project) {
    this.selectedProject = project

  }

  getWidgets2(projects) {
      //console.log('Datain getWidgets: ',DataIn)
      if(isEmpty(projects)) return
      projects.forEach((cliente,index)=>{
        let indexColor=0
/*         console.log(cliente) */
          this.projects.push({
            id:cliente.id,
            name:cliente.NameCliente,
            lic:cliente.lic,
            totales:[]
          })
        //console.log(filter)
        //console.log(this.projects, this.projects[index])
        cliente.Total=cliente.Total.sort((a,b) => a.Order - b.Order)
        cliente.Total.forEach((ETotales: any)=>{
          if(indexColor>=this.colors.length)indexColor=0
          if(ETotales.Tipo != 11) {
            this.projects[index].totales.push({
              'widget': {
                'title': ETotales.NombreTipo,
                'tipo':ETotales.Tipo,
                'color':this.colors[indexColor],
                'data': {
                  //'label': 'Conversaciones',
                  'count': ETotales.Total,
                  //'extra': {
                    //'label': 'Ayer',
                    //'count': 43
                  //}
                },
                //'detail': 'Tienes 4 Mensajes en Cola y Ayer 2.'
                }
              })
              indexColor++
          }else{
            this.projects[index].totales.push({
              'widget': {
                'title': ETotales.NombreTipo,
                'tipo':ETotales.Tipo,
                'color':this.colors[indexColor],
                'data': {
                  //'label': 'Conversaciones',
                  //'count': ETotales.Total,
                  'extra': {
                    'data': JSON.parse(ETotales.Total),
                    //'count': 43
                  }
                },
                //'detail': 'Tienes 4 Mensajes en Cola y Ayer 2.'
                }
           })
             indexColor=indexColor+2
         }

      })

    })
    if(this.projects.length==1){
      console.log('aqui 129')
      this.timerStop()
      this.noShow=false
    }else{
      this.timerStart()
      this.noShow=true
    }
  this.selectedProject=this.projects[0]
  console.log("SelectedProject");
  console.log(this.selectedProject);
  }

  loadClientes(): Promise<string[]> {
    return new Promise<string[]>((resolve, reject) => {
      this.getTableServiceV2.TableURL = "clientes";
      this.getTableServiceV2.getData().then((clientes: any) => {
          //console.log(clientes);          

          let lics: string[] = [];
          try {
            if(isEmpty(clientes)) return

            //clientes.forEach((client: any) => {
              ////client.canales.forEach((canal) => {
                ////lics.push(canal.lic);
              ////});
            //});
            resolve(clientes);
          } catch (error) {
            console.error(error);
            reject(error);
          }
        }).catch((Error) => { console.error(Error) });
    });
  }

  loadAgentes(): Promise<string[]> {
    return new Promise<string[]>((resolve, reject) => {
      this.getTableServiceV2.TableURL = "agentes-adm";
      this.getTableServiceV2.getData().then((agentes: any) => {
          console.log(agentes);          
        let allagentes=[]
          try {
            if(isEmpty(agentes)) return
            agentes[0].clientes.forEach((agente) => {
              allagentes.push({
                idUser:agente.idUser,
                idEmpresa:agente.idEmpresa,
                Avatar:agente.Avatar,
                name:`${agente.Nombre} ${agente.Apellido}`,
              })
            })
            resolve(allagentes);
          } catch (error) {
            console.error(error);
            reject(error);
          }
        }).catch((Error) => { console.error(Error) });
    });
  }

  getAgent(id){
    return this.agentes.find((agent)=>agent.idUser==id) || ""
  }



  drop(event: CdkDragDrop<any[]>) {
    //moveItemInArray(this.selectedProject.totales, event.previousIndex, event.currentIndex);
  }

  PlayPause(value){
    console.log("Pause: ", this.Pause,value)
    if(value){
      //Stop timer
      this.timerStop();

    }else{
      //Start timer
/*       this.timerStart(); */

    }

    this.Pause=!this.Pause
  }

  timerStart(){
    console.log("Start al timer")
      this.timer=setInterval(() => {
        //console.log(this.IndexProject, this.projects.length-1)
        if(this.IndexProject == this.projects.length-1) this.IndexProject=0
        this.selectedProject=this.projects[this.IndexProject]
        this.IndexProject++
      }, 18000);
  }
  
  timerStop() {
    console.log("Stop al timer")
    clearInterval(this.timer);
  }


  SubDash() {

    this._SubscriptionGraphqlService.newChat
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (Dash) => {
          console.log('newDash: ',Dash)
        },
        (err) => {
          console.log("Err");
          console.log(err);
          this.SubDash();
        }
      );
  }


}


export interface widget {
  widget: {
    title: string,
    tipo?: string,
    color?:string,
    data: {
      label: string,
      count: number,
      extra?: {
        data: any[],
      },
    },
  }
}


//project-dashboardK