import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FuseSharedModule } from '@fuse/shared.module';
import { NgDialogAnimationService } from 'ng-dialog-animation';
import { DemoMaterialModule } from './material/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { SliceStringPipe } from './pipes/slice-string.pipe';
import { PipePhone } from './pipes/PipePhone.pipe';
import { CtrlBDirective } from './directive/ctrlB.directive';
import { CtrlKeysDirective } from './directive/ctrlKeys.directive';
import { ImageNotFoundPipe } from './pipes/image-not-found.pipe';
import { AudioPipe } from './pipes/audio.pipe';

import { ChatView3Component } from './components/chat-view3/chat-view3.component';
import { SimuladorComponent } from './components/simulador/simulador.component';

import { SimuladorService } from './services/simulador.service';
import { BypassPipe } from './pipes/bypass.pipe';
import { MaterialModule } from 'app/material.module';
import { DashboardCardComponent } from './components/dashboard-card/dashboard-card.component';
import { EcommerceProductsService } from 'app/main/apps/e-commerce/products/products.service';
// import { MatTableBaseBillingComponent } from './components/mat-table-base-billing/mat-table-base-billing.component';
// import { MatTableBaseComponent } from './components/mat-table-base-billing/mat-table-base.component';
// import { SharedHeaderComponent } from './components/shared-header/shared-header.component';

@NgModule({ 
  declarations: [
    SimuladorComponent, 
    ChatView3Component, 
    DashboardCardComponent,
    // MatTableBaseComponent,
    // SharedHeaderComponent,

    SliceStringPipe, 
    PipePhone, 
    CtrlBDirective, 
    CtrlKeysDirective, 
    ImageNotFoundPipe, 
    AudioPipe, 
    BypassPipe,
  ],
  imports: [
    TranslateModule.forChild(),
    CommonModule,
    DemoMaterialModule,
    TranslateModule,
    PickerModule,
    ReactiveFormsModule,
    FormsModule,
    FuseSharedModule,
    MaterialModule
  ],
  exports: [
    SimuladorComponent, 
    ChatView3Component, 
    DashboardCardComponent,
    // MatTableBaseComponent,
    // SharedHeaderComponent,
    SliceStringPipe, 
    PipePhone, 
    CtrlBDirective, 
    CtrlKeysDirective, 
    ChatView3Component,
    AudioPipe,
    TranslateModule,
    PickerModule,
    DemoMaterialModule,
    BypassPipe
  ],
  providers:[
    SimuladorService,
    NgDialogAnimationService,
    EcommerceProductsService
  ]
})
export class SharedModule { }
