import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { navigation } from 'app/navigation/navigation';
import { LoginSessionService } from 'app/services/global/LoginSession.service';
import { SessionUserService } from '../../../shared/services/session-user.service';
import { OfflineService } from 'app/services/global/Offline.service';
import { FakeDbService } from 'app/fake-db/fake-db.service';
import { MutationService } from 'app/fake-db/Mutation/mutation.service';
import { Apollo } from "apollo-angular";

import { MassiveMessageService } from '../../../services/global/massive-message.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';

@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ToolbarComponent implements OnInit, OnDestroy {
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];
    NameUser: string;
    Prefijo: String = 'Perfil.';
    /*     Token: String = ""; */
    Token = localStorage.getItem('login');
    Data: any = "";
    totalMessageToSend: number = 0;
    countMessageSent: number = 0;
    countMessageBad: number = 0;
    showCounter: boolean = false;
    showCounterInvoice: boolean = false;
    ShowCanceledInvoice: boolean = false;
    showSuccesful: boolean = false;
    showSuccesInvoice: boolean = false;
    ShowSuccesInvoiceCanceled: boolean = false;
    totalSent: number = 0;
    // Private
    private _unsubscribeAll: Subject<any>;
    invisible: boolean = true

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(
        private _FakeDbService: FakeDbService,
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        private _LoginSessionService: LoginSessionService,
        private _OfflineService: OfflineService,
        private sessionUser: SessionUserService,
        private _mutation: MutationService,
        private apollo: Apollo,
        private messageService: MassiveMessageService,
        private router: Router
    ) {
        /*           const helper = new JwtHelperService();
                let decodedToken = helper.decodeToken(localStorage.getItem('login')); */
        /*         /this.Token= decodedToken.replace(/["']/g, "") */

        this.Token = this._LoginSessionService.GetTokenTemp();
        this.Data = this._LoginSessionService.GetDataUser()

        this.NameUser = _LoginSessionService.GetNameUser()
        // Con esto resolvi el problemas del nombre del usuario que decie SINNAME
        _LoginSessionService.eDataLogin.subscribe(Datos => {
            this.NameUser = Datos.users.active.Data.Nombre
        })

        // Set the defaults
        this.userStatusOptions = [
            {
                title: 'Online',
                icon: 'icon-checkbox-marked-circle',
                color: '#4CAF50'
            },
            {
                title: 'Away',
                icon: 'icon-clock',
                color: '#FFC107'
            },
            {
                title: 'Do not Disturb',
                icon: 'icon-minus-circle',
                color: '#F44336'
            },
            {
                title: 'Invisible',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#BDBDBD'
            },
            {
                title: 'Offline',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#616161'
            }
        ];

        this.languages = [
            {
                id: 'en',
                title: 'English',
                flag: 'us'
            },
            {
                id: 'es',
                title: 'Español',
                flag: 'es'
            },
            {
                id: 'pr',
                title: 'Português',
                flag: 'pr'
            },
            /*             {
                            id   : 'tr',
                            title: 'Turkish',
                            flag : 'tr'
                        } */
        ];

        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, { id: this._translateService.currentLang });


        // Message to send
        this.messageService.numberOfMessageToSend.subscribe((res: number) => {
            this.totalMessageToSend = res;
        });
        this.messageService.countMessageSent.subscribe((res: number) => {
            this.countMessageSent = res;
        });
        this.messageService.countMessageBad.subscribe((res: number) => {
            this.countMessageBad = res;
        });
        this.messageService.showCounter.subscribe((res: boolean) => {
            this.showCounter = res;
        });
        this.messageService.showCounterInvoice.subscribe((res: boolean) => {
            this.showCounterInvoice = res;
        });
        this.messageService.ShowCanceledInvoice.subscribe((res: boolean) => {
            this.ShowCanceledInvoice = res;
        });
        this.messageService.processSuccessful.subscribe((res: boolean) => {
            this.showSuccesful = res;
        });
        this.messageService.processSuccessInvoice.subscribe((res: boolean) => {
            this.showSuccesInvoice = res;
        });
        this.messageService.processSuccessInvoiceCanceled.subscribe((res: boolean) => {
            this.ShowSuccesInvoiceCanceled = res;
        });
        this.messageService.totalSent.subscribe((res: number) => {
            this.totalSent = res;
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void {
        // Do your search here...
    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);
        this.saveLanguageToStorage(lang.id);
    }

    CloseSeccion() {
        //this.Token = this.LoginToken.Token
        /*         const DataOffline = {
                    "idUser": this.Data.idUser,
                    "Online": false
                } */
        let JSOND = {
            "idUser": this.Data.idUser,
            "online": false,
        }
        this._mutation.UpdateUserOnline(this.apollo, this.Token, JSOND)
        //TODO Aqui hay que llamar a la api que tambien lo va a mandar offline
        /*         this._mutation.UpdateUserOnline(this.apollo, this.Token, DataOffline).then((res: any) => {
                    if (res) { */
        sessionStorage.setItem('LogOut', 'true')
        localStorage.clear()
        sessionStorage.clear()
        this._FakeDbService.ClearAllAPI()
        this._LoginSessionService.ackStop()
        /*             } */
        /*         }) */

        this.sessionUser.stopIdle();
    }

    saveLanguageToStorage(language: string) {
        localStorage.setItem('app.language', language);
    }

    checkInvisible() {

        this.invisible = !this.invisible

        let JSOND = {
            "idUser": this.Data.idUser,
            "online": this.invisible,
        }

        this._mutation.UpdateUserOnline(this.apollo, this.Token, JSOND)
            .then((res) => {
                //TODO sacar snackbar indicando que se hizo el cambio
            })

    }

}
