import { Injectable } from '@angular/core';
import { FileManagerFakeDb } from 'app/fake-db/file-manager';

@Injectable({
  providedIn: 'root'
})
export class TakeDateService {

  public daysOfTheWeek = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado','Domingo'];

  constructor() { }

  takeDate(date: Date): string {
    return `${this.daysOfTheWeek[date.getDay() - 1]}, ${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
  }

  init(data: string) {
    if( !(typeof parseInt(data) === 'number') ) {
      return data;
    }
    const date = new Date(parseInt(data));
    return this.takeDate(date);
  }

}
