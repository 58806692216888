import { HttpClient } from "@angular/common/http";
import { Apollo } from "apollo-angular";
import gql from "graphql-tag";
import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class DashboardMensajesFakeDb {
    Token = sessionStorage.getItem('DataLogin')

    constructor(
        private apollo: Apollo,
        public _httpClient: HttpClient
    ) { }

    public static dashboard_MSG_2 = []

    public static dashboard_MSG = {
        colum: [
            { colum: 'contacto', type: 'string', head: 'Contacto', visible: true, isModelProperty: true },
            { colum: 'type', type: 'string', head: 'Tipo', visible: true, isModelProperty: true },
            { colum: 'cuenta', type: 'string', head: 'Cuenta', visible: true, isModelProperty: true },
            { colum: 'fecha_i', type: 'string', head: 'Fecha Inicio', visible: true, isModelProperty: true },
            { colum: 'agente_exc', type: 'string', head: 'Agentes exclusivos', visible: true, isModelProperty: true },
            { colum: 'requerimiento', type: 'string', head: 'Requerimiento', visible: true, isModelProperty: true },
            { colum: 'mensajes', type: 'string', head: 'Mensajes', visible: true, isModelProperty: true },
            { colum: 'menu', type: 'menu', head: 'Menú', visible: true },
        ],
        type: ['string', 'string', 'string', 'string', 'string', 'string', 'string', 'menu'],
        menu: ["Chat /chrome_reader_mode", "Reasignar/swap_horiz"],
        clientes: [
            {
                id: 1,
                atajos: [
                    {
                        "id": "06160A6C-9377-B1F3-D179-CCD4FCE9A2DA",
                        "contacto": "Brayan soteldo",
                        "type": "in",
                        "cuenta": "51993202513",
                        "fecha_i": new Date(),
                        "agente_exc": "N/A",
                        "requerimiento": "Falla",
                        "mensajes": 30
                    },
                    {
                        "id": "06160A6C-9377-B1F3-D179-CCD4FCE9A2DA",
                        "contacto": "Rodrigo Matilde",
                        "type": "out",
                        "cuenta": "51993202598",
                        "fecha_i": new Date(),
                        "agente_exc": "N/A",
                        "requerimiento": "Soporte",
                        "mensajes": 80
                    },

                ]
            },
            {
                id: 2,
                atajos: [
                    {
                        "id": "06160A6C-9377-B1F3-D179-CCD4FCE9A3D6",
                        "contacto": "Juan Tovias",
                        "type": "in",
                        "cuenta": "51993201497",
                        "fecha_i": "11-02-2021 10:15am",
                        "agente_exc": "N/A",
                        "requerimiento": "Técnico",
                        "mensajes": 80
                    },
                    {
                        "id": "06160A6C-9377-B1F3-D179-CCD4FCE9A456",
                        "contacto": "Carlos zapata",
                        "type": "out",
                        "cuenta": "51993206395",
                        "fecha_i": "11-02-2021 10:18am",
                        "agente_exc": "N/A",
                        "requerimiento": "Técnico",
                        "mensajes": 1
                    }
                ]
            }
        ]
    }

    public static projects = [
        {
            'name': 'Cable Hogar',
            'lic': '584121311690',
            'idCliente': '2525'
        },
        {
            'name': 'Conex Perú',
            'lic': '51957052872',
            'idCliente': '2527'
        },
        {
            'name': 'Fibex Telecom',
            'lic': '584120202020',
            'idCliente': '2526'
        }
    ];

    public static MsgByEmpLic(apollo: Apollo, Token: string, _httpClient: HttpClient) {
        let data = apollo.watchQuery<any>({
            query: ChatByLicEmp,
            variables: {
                token: Token,
                "Lic": "584120202020@c.us" //deben ser valores dinamicos este campo va a tener la capacidad de recibir en arreglo
            }
        })
            .valueChanges
            .subscribe((result) => {
                let Msg = result.data.ChatByLic2 //Guardo la data obtenida

                let lic = "584120202020@c.us"

                const DataUserByCliente = {
                    lic: lic,
                    idEmpresa: '1',
                    colum: [
                        { colum: 'contacto', type: 'phone', head: 'Contacto', visible: true, isModelProperty: true },
                        { colum: 'type', type: 'string', head: 'Tipo', visible: true, isModelProperty: true },
                        { colum: 'cuenta', type: 'phone', head: 'Licencia', visible: true, isModelProperty: true },
                        { colum: 'fecha_i', type: 'string', head: 'Fecha Inicio', visible: true, isModelProperty: true },
                        { colum: 'agente_exc', type: 'string', head: 'Agentes exclusivos', visible: true, isModelProperty: true },
                        { colum: 'requerimiento', type: 'string', head: 'Requerimiento', visible: true, isModelProperty: true },
                        { colum: 'mensajes', type: 'string', head: 'Mensajes', visible: true, isModelProperty: true },
                        { colum: 'menu', type: 'menu', head: 'Menú', visible: true },
                    ],
                    type: ['string', 'string', 'string', 'string', 'string', 'string', 'string', 'menu'],
                    menu: ["Chat/chrome_reader_mode", "Reasignar/swap_horiz"],
                    rows: Msg
                }

            });
    }

    /* apollo: Apollo, Token: string, _httpClient: HttpClient */
    MsgDashboard(lic: any) {
        return new Promise((resolve, reject) => {
            try {

                let data = this.apollo.watchQuery<any>({
                    query: ChatDash,
                    variables: {
                        token: this.Token,
                        "Lic": lic
                    }
                })
                    .valueChanges
                    .subscribe((result) => {
                        let Msg = result.data.MensajesDash //Guardo la data obtenida
                        resolve(Msg)
                    });

            } catch (error) {
                console.error(error)
                reject(error)
            }
        })

    }

}

const ChatByLicEmp = gql`
query data($token:String,$Lic:String){
    ChatByLic2(token:$token,Lic:$Lic){
        idEmpresa
        phone
        idConversacion
        idmessage
        timestamp
        me_to
        contentType
        content
        TimeRead
        status
        statusc
        readmsg
        operador
        lic
        tag
      }
  }  
`

const ChatDash = gql`
query MensajesDash($token:String,$Lic:String){
    MensajesDash(token:$token, lic:$Lic ){
        operador
        lic
        phone
        idEmpresa
        idConversacion
        fecha_i:TimeRead
    }
}
`